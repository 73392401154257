<template>
  <div>
    <Sheet
      v-model="formHistorySheet"
      width="50vw"
      no-padding
      @input="closeFormHistorySheet"
    >
      <!-- title -->

      <template #title> Form Entry History </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getFormHistory()"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          height="
            calc(100vh - 200px)
          "
        >
          <div id="history">
            <template v-if="history.length">
              <div v-for="item in history" :key="item.id" class="entry">
                <div class="header">
                  {{ item.changes.length }}
                  {{ item.changes.length > 1 ? "Changes" : "Change" }}
                </div>

                <div class="content">
                  <div class="changes-meta">
                    <div>{{ item.changedBy }}</div>
                    <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                    <div>{{ item.changedAt }}</div>
                  </div>

                  <div
                    v-for="(change, idx) in item.changes"
                    :key="idx + 1"
                    class="change"
                  >
                    <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

                    <div v-if="change.name === 'isDeleted'">
                      <span
                        v-if="Number(change.newValue)"
                        class="font-semibold"
                      >
                        "Deleted"
                      </span>
                      <span v-else class="font-semibold">"Restored"</span>
                      the item
                    </div>

                    <div v-else-if="change.oldValue">
                      Modified {{ change.name }} from
                      <span class="font-semibold">"{{ change.oldValue }}"</span>
                      to
                      <span class="font-semibold">"{{ change.newValue }}"</span>
                    </div>

                    <div v-else>
                      Assigned
                      <span class="font-semibold">"{{ change.newValue }}"</span>
                      as
                      {{ change.name }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div v-else class="no-history">No data found</div>
          </div>
        </BaseScrollbar>
      </div>
    </Sheet>
  </div>
</template>
<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import { form } from "@/api/factory.js";

export default {
  name: "FormEntryHistory",

  components: {
    Sheet,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    processId: {
      type: Number,
      required: true,
    },

    workflowId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      changes: [],
      history: [],
      formHistorySheet: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.getFormHistory();
        }
      },
    },
  },

  methods: {
    async getFormHistory() {
      this.formHistorySheet = true;
      this.history = [];

      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.formHistory(
        this.workflowId,
        this.processId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // console.log(payload);
      if (payload) {
        let data = payload;
        this.history =
          data.length &&
          data.map((item) => ({
            id: this.$nano.id(),
            changedBy: item.modifiedBy,
            changedAt: this.$day.format(item.modifiedAt),
            changes: item.fields,
          }));
      }
    },

    closeFormHistorySheet() {
      this.formHistorySheet = false;

      this.$emit("update:value", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  padding: 16px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
