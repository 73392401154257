<template>
  <div id="logo" class="cursor-pointer" @click="homePage">
    <img :src="_logo" :height="logoHeight" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",

  computed: {
    ...mapState(["darkTheme"]),

    _logo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return require(`@/assets/logo/sobha-mark-logo.png`);
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return require(`@/assets/logo/psg-logo.png`);
      } else if (
        origin === "http://172.16.1.118" ||
        origin === "http://localhost:8080"
      ) {
        return require(`@/assets/logo/kayaku-logo.png`);
      } else {
        return require(`@/assets/logo/name-${
          this.darkTheme ? "dark" : "light"
        }.png`);
      }
    },

    logoHeight() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return "28px";
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return "42px";
      } else if (origin === "http://172.16.1.118") {
        return "38px";
      } else {
        return "24px";
      }
    },
  },

  methods: {
    homePage() {
      if (this.$store.state.session.profile) {
        if (this.$store.state.profileMenus.length) {
          if (
            this.$store.state.profileMenus.find((row) => row.workspaces)?.Menu[
              "Overview"
            ]
          ) {
            if (this.$route.name !== "repositories-browse") {
              this.$router.push({ name: "repositories-browse" });
            }
            return;
          }
        }
      }
      if (this.$route.name !== "workflows-overview") {
        this.$router.push({ name: "workflows-overview" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#logo {
  display: flex;
  align-items: center;

  // img {
  //   height: 24px;
  // }
}
</style>
