<template>
  <div id="custom-process-form-view" :style="{ width: formWidth }">
    <!-- form -->
    <BaseScrollbar height="calc(100vh - 130px)">
      <div class="content">
        <div class="row">
          <div
            class="q-pr-sm row"
            :class="
              $q.screen.width > 1200
                ? 'col-lg-6 col-md-6 col-sm-12'
                : 'col-lg-6 col-md-12 col-sm-12'
            "
            :style="!inbox ? 'height:10px;' : ''"
          >
            <div class="col-12 header" :class="!inbox ? 'q-mb-sm' : ''">
              Customer Information
            </div>
            <table class="col-12 q-mb-sm">
              <tbody>
                <tr>
                  <td>Claimant Info</td>
                  <td>
                    <SelectField
                      :value="getModel('Claimant Info')"
                      :options="claimantInfoList"
                      class="custom-controls"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Claimant Info')"
                      @onBlur="autoSave('Claimant Info')"
                    />
                  </td>
                  <td>First Name</td>
                  <td>
                    <TextField
                      :value="getModel('First Name')"
                      class="custom-controls"
                      :highlight="checkHighlight('First Name')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'First Name')"
                      @onBlur="autoSave('First Name')"
                    />
                  </td>
                  <td>Last Name</td>
                  <td colspan="2">
                    <TextField
                      :value="getModel('Last Name')"
                      class="custom-controls"
                      :highlight="checkHighlight('Last Name')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Last Name')"
                      @onBlur="autoSave('Last Name')"
                    />
                  </td>
                </tr>
                <tr>
                  <td>DOB</td>
                  <td>
                    <DateField
                      :value="getModel('DOB')"
                      class="custom-controls"
                      :highlight="checkHighlight('DOB')"
                      :is-readonly="isReadonly || !fieldVerification"
                      :maximum-validation="true"
                      @input="updateModel($event, 'DOB')"
                      @onBlur="autoSave('DOB')"
                    />
                  </td>
                  <td>Current Age</td>
                  <td>
                    <NumberField
                      :value="getModel('Current Age')"
                      class="custom-controls"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Current Age')"
                      @onBlur="autoSave('Current Age')"
                    />
                  </td>
                  <td>Age at DOC</td>
                  <td>
                    <NumberField
                      :value="getModel('Age at Doc')"
                      class="custom-controls"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Age at Doc')"
                      @onBlur="autoSave('Age at Doc')"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td colspan="5">
                    <TextAreaField
                      :value="getModel('Address')"
                      :highlight="checkHighlight('Address')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Address')"
                      @onBlur="autoSave('Address')"
                    />
                  </td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>
                    <!-- <SelectField
                      :value="getModel('City')"
                      :options="cityList"
                      class="custom-controls"
                      :highlight="checkHighlight('City')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="
                        {
                          updateModel($event, 'City');
                          autoSave();
                        }
                      "
                    /> -->
                    <TextField
                      :value="getModel('City')"
                      class="custom-controls"
                      :highlight="checkHighlight('City')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'City')"
                      @onBlur="autoSave('City')"
                    />
                  </td>
                  <td>Province</td>
                  <td>
                    <SelectField
                      :value="getModel('Province')"
                      :options="provinceList"
                      class="custom-controls"
                      :highlight="checkHighlight('Province')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Province')"
                      @onBlur="autoSave('Province')"
                    />
                  </td>
                  <td>Postal Code</td>
                  <td>
                    <TextField
                      :value="getModel('Postal Code')"
                      class="custom-controls"
                      :highlight="checkHighlight('Postal Code')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Postal Code')"
                      @onBlur="autoSave('Postal Code')"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Phone No.</td>
                  <td>
                    <TextField
                      :value="getModel('Phone No.')"
                      class="custom-controls"
                      :highlight="checkHighlight('Phone No.')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Phone No.')"
                      @onBlur="autoSave('Phone No.')"
                    />
                  </td>
                  <td>Email ID</td>
                  <td>
                    <TextField
                      :value="getModel('Email ID')"
                      class="custom-controls"
                      :highlight="checkHighlight('Email ID')"
                      :is-readonly="isReadonly || !fieldVerification"
                      @input="updateModel($event, 'Email ID')"
                      @onBlur="autoSave('Email ID')"
                    />
                  </td>
                  <td>Name of Deceased</td>
                  <td>
                    <TextField
                      :value="getModel('Name of Deceased')"
                      class="custom-controls"
                      :is-readonly="
                        isReadonly ||
                        !isNameOfDeceasedEditable ||
                        !fieldVerification
                      "
                      @input="updateModel($event, 'Name of Deceased')"
                      @onBlur="autoSave('Name of Deceased')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                selectedProcess.activityId !== '001f5CdkTDtjCDn3logTT' &&
                selectedProcess.activityId !== 'szS6B-q1x9acpB83rP6jT' &&
                !isReadonly &&
                inbox
              "
              class="col-12"
            >
              <template v-if="!isReadonly">
                <template v-if="fieldVerification">
                  <BaseButton
                    label="Confirm"
                    color="orange"
                    style="float: right"
                    class="custom-button"
                    @click="confirmCustomerInfo"
                  />
                </template>
                <template v-else>
                  <BaseButton
                    label="Confirmed"
                    color="green"
                    style="float: right"
                    class="custom-button"
                  />
                  <BaseButton
                    label="Edit"
                    color="orange"
                    style="float: right"
                    class="custom-button q-mr-sm"
                    @click="unConfirmCustomerInfo"
                  />
                </template>
              </template>
              <template v-else-if="!fieldVerification">
                <BaseButton
                  label="Confirmed"
                  color="green"
                  style="float: right"
                  class="custom-button"
                />
              </template>
            </div>

            <div class="header col-12" :class="!inbox ? 'q-mb-sm' : ''">
              Loan Information
            </div>
            <table class="col-12 q-mb-sm loan-info">
              <tbody>
                <tr>
                  <td>Group Policy</td>
                  <td>{{ getModel("Group Policy") || "-" }}</td>
                  <td>Payment Frequency</td>
                  <td>{{ getModel("Payment Frequency") || "-" }}</td>
                </tr>
                <tr>
                  <td>Loan No.</td>
                  <td>{{ getModel("Loan No.") || "-" }}</td>
                  <td>No. of Loan Payments</td>
                  <td>
                    {{ getModel("No. of Loan Payments") || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>Loan Start Date</td>
                  <td>{{ getModel("Loan Start Date") || "-" }}</td>
                  <td>Last Remitted Date</td>
                  <td>{{ getModel("Last Remitted Date") || "-" }}</td>
                </tr>
                <tr>
                  <td>Term</td>
                  <td>{{ getModel("Term") || "-" }}</td>
                  <td>Cancellation Date</td>
                  <td>{{ getModel("Cancellation Date") || "-" }}</td>
                </tr>
                <tr>
                  <td>Loan End Date</td>
                  <td>{{ getModel("Loan End Date") || "-" }}</td>
                  <td>Cancel Reason</td>
                  <td>{{ getModel("Cancel Reason") || "-" }}</td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                selectedProcess.activityId !== '001f5CdkTDtjCDn3logTT' &&
                selectedProcess.activityId !== 'szS6B-q1x9acpB83rP6jT' &&
                !isReadonly &&
                inbox
              "
              class="col-12"
            >
              <template v-if="!loanInfoVerify">
                <BaseButton
                  label="Confirm"
                  color="orange"
                  style="float: right"
                  class="custom-button"
                  @click="confirmLoanInfo"
                />
              </template>
              <template v-else-if="loanInfoVerify">
                <BaseButton
                  label="Confirmed"
                  color="green"
                  style="float: right"
                  class="custom-button"
                />

                <BaseButton
                  label="Edit"
                  color="orange"
                  style="float: right"
                  class="custom-button q-mr-sm"
                  @click="unConfirmLoanInfo"
                />
              </template>
            </div>
          </div>
          <div
            class="row"
            :class="
              $q.screen.width > 1200
                ? 'col-lg-6 col-md-6 col-sm-12'
                : 'col-lg-6 col-md-12 col-sm-12'
            "
          >
            <div class="col-12 header q-mb-sm">Claim Information</div>
            <div class="col-12 full-border q-pa-sm">
              <table class="claim-info q-mb-sm table-content single-border">
                <thead>
                  <tr>
                    <th>Claim No.</th>
                    <th>Reference No.</th>
                    <th>Claim Date</th>
                    <th>Claim Package Sent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getModel("Claim No.") || "-" }}</td>
                    <td>{{ getModel("Reference No.") || "-" }}</td>
                    <td>
                      <DateField
                        :value="getModel('Claim Date')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Claim Date')"
                        @onBlur="autoSave('Claim Date')"
                      />
                    </td>
                    <td>{{ getModel("Claim Package Sent") || "-" }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="claim-info q-mb-sm table-content single-border">
                <thead>
                  <tr>
                    <th>Claim Received Date</th>
                    <th>Processing Date</th>
                    <th>Initials</th>
                    <th>Language</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getModel("Claim Received Date") || "-" }}</td>
                    <td>{{ getModel("Processing Date") || "-" }}</td>
                    <td>
                      <TextField
                        :value="getModel('Initials')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Initials')"
                        @onBlur="autoSave('Initials')"
                      />
                    </td>
                    <td>
                      <SelectField
                        :value="getModel('Language')"
                        :options="languageList"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Language')"
                        @onBlur="autoSave('Language')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="claim-info single-border">
                <tbody>
                  <tr>
                    <td>Benefit Claimed</td>
                    <td class="medium">
                      <SelectField
                        :value="getModel('Benefit Claimed')"
                        :options="_productList"
                        class="custom-controls"
                        :is-readonly="
                          isReadonly ||
                          claimInfoVerify ||
                          !isChangeBenefitClaimed
                        "
                        @input="updateModel($event, 'Benefit Claimed')"
                        @onBlur="autoSave('Benefit Claimed')"
                      />
                    </td>
                    <td>Loan Payment Amount</td>
                    <td class="medium">
                      <NumberField
                        :value="getModel('Loan Payment Amount')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Loan Payment Amount')"
                        @onBlur="autoSave('Loan Payment Amount')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Reason/Cause</td>
                    <td class="medium">
                      <TextField
                        :value="getModel('Reason/Cause')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Reason/Cause')"
                        @onBlur="autoSave('Reason/Cause')"
                      />
                    </td>
                    <td>LPP Premium Amount</td>
                    <td class="medium">
                      <NumberField
                        :value="getModel('LPP Premium Amount')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'LPP Premium Amount')"
                        @onBlur="autoSave('LPP Premium Amount')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Claim Decision</td>
                    <td class="medium">
                      <TextField
                        :value="getModel('Claim Decision')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Claim Decision')"
                        @onBlur="autoSave('Claim Decision')"
                      />
                    </td>
                    <td>Principal Balance at DOC</td>
                    <td class="medium">
                      <NumberField
                        :value="getModel('Principal Balance At Doc')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="updateModel($event, 'Principal Balance At Doc')"
                        @onBlur="autoSave('Principal Balance At Doc')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Decline Reason</td>
                    <td class="medium">
                      <SelectField
                        :value="getModel('Decline Reason')"
                        :options="declineReasonList"
                        class="custom-controls"
                        :is-readonly="true"
                        @input="updateModel($event, 'Decline Reason')"
                        @onBlur="autoSave('Decline Reason')"
                      />
                    </td>
                    <td>LumpSum Principal Balance</td>
                    <td class="medium">
                      <NumberField
                        :value="getModel('LumpSum Principal Balance')"
                        class="custom-controls"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="
                          updateModel($event, 'LumpSum Principal Balance')
                        "
                        @onBlur="autoSave('LumpSum Principal Balance')"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <MultipleChoiceField
                        :value="getModel('Authorization')"
                        :options="authorizationTypes"
                        :options-per-line="3"
                        class="custom-controls no-border"
                        :is-readonly="isReadonly || claimInfoVerify"
                        @input="
                          {
                            updateModel($event, 'Authorization');
                            autoSave('Authorization');
                          }
                        "
                      />
                    </td>
                    <td>
                      <template
                        v-if="
                          selectedProcess.activityId !==
                            '001f5CdkTDtjCDn3logTT' &&
                          selectedProcess.activityId !==
                            'szS6B-q1x9acpB83rP6jT' &&
                          !isReadonly &&
                          inbox
                        "
                      >
                        <template v-if="!claimInfoVerify">
                          <BaseButton
                            label="Confirm"
                            color="orange"
                            style="margin: auto"
                            class="custom-button"
                            @click="confirmClaimInfo"
                          />
                        </template>
                        <template v-else-if="claimInfoVerify">
                          <BaseButton
                            label="Confirmed"
                            color="green"
                            style="margin: auto"
                            class="custom-button"
                          />

                          <BaseButton
                            label="Edit"
                            color="orange"
                            style="margin: auto; margin-top: 10px"
                            class="custom-button"
                            @click="unConfirmClaimInfo"
                          />
                        </template>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 q-mt-sm full-border q-pa-sm">
              <div class="col header">Required Documents</div>
              <MultipleChoiceField
                :value="getModel('Required Documents')"
                :options="_documentCheckList"
                :options-per-line="3"
                class="custom-controls q-mt-sm"
                :is-readonly="isReadonly"
                @input="
                  {
                    updateModel($event, 'Required Documents');
                    autoSave('Required Documents');
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col q-mt-sm">
            <div class="q-pl-sm q-mb-sm">
              <Tabs v-model="tab" :tabs="tabs" />
            </div>
            <template v-if="tab === 'Claim Status'">
              <div v-if="claimStatusTotalItems" class="row">
                <div class="col">
                  <Pagination
                    class="custom-form-table"
                    :total-items="claimStatusTotalItems"
                    :current-page.sync="claimStatusCurrentPage"
                    :items-per-page.sync="claimStatusItemsPerPage"
                    @loadAll="loadAllClaimStatus"
                  />
                </div>
                <div class="col-auto q-mt-xs q-ml-sm">
                  <ExpandableSearch
                    class="q-mr-sm"
                    @search="claimStatusSearchRow"
                  />
                </div>
              </div>
              <BaseScrollbar height="330px">
                <table
                  class="tab-table table-content q-mt-sm"
                  :class="!claimStatusTotalItems ? 'empty' : ''"
                >
                  <thead>
                    <tr>
                      <th v-if="inbox" class="actions">
                        <BaseActionButton
                          v-tooltip:top="'add row'"
                          color="primary"
                          icon="eva-plus"
                          no-border
                          class="q-ml-xs small"
                          size="16px"
                          @click="addClaimStatus"
                        />
                      </th>
                      <th v-for="column in claimStatusColumns" :key="column.id">
                        {{ column.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in claimStatusValues"
                      :key="'claimstatusrow' + index"
                    >
                      <td v-if="inbox" class="actions">
                        <BaseActionButton
                          v-tooltip:top="'edit'"
                          color="secondary"
                          icon="mdi-square-edit-outline"
                          class="q-ml-xs small"
                          size="16px"
                          is-flat
                          no-border
                          @click="editClaimStatus(item)"
                        />
                      </td>
                      <td v-for="column in claimStatusColumns" :key="column.id">
                        {{ item[column.name] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BaseScrollbar>
            </template>
            <template v-else-if="tab === 'Daily Data Summary'">
              <div v-if="dailyDataStatusTotalItems" class="row">
                <div class="col">
                  <Pagination
                    class="custom-form-table"
                    :total-items="dailyDataStatusTotalItems"
                    :current-page.sync="dailyDataStatusCurrentPage"
                    :items-per-page.sync="dailyDataStatusItemsPerPage"
                    @loadAll="loadAllDailyDataStatus"
                  />
                </div>
                <div class="col-auto q-mt-xs q-ml-sm">
                  <ExpandableSearch
                    class="q-mr-sm"
                    @search="dailyDataStatusSearchRow"
                  />
                </div>
              </div>
              <BaseScrollbar height="330px">
                <table
                  class="tab-table q-mt-sm"
                  :class="!dailyDataStatusTotalItems ? 'empty' : ''"
                >
                  <thead>
                    <tr>
                      <th
                        v-for="column in dailyDataStatusColumns"
                        :key="column.id"
                      >
                        {{ column.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in dailyDataStatusValues"
                      :key="'dailydatarow' + index"
                    >
                      <td
                        v-for="column in dailyDataStatusColumns"
                        :key="column.id"
                      >
                        {{ item[column.name] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BaseScrollbar>
            </template>

            <template v-else-if="tab === 'Benefit Payment'">
              <div id="paymentTable">
                <template v-if="inbox">
                  <div
                    v-if="!hasLifeType && !hasCIClaimsType"
                    class="row controls"
                  >
                    <div class="col">
                      <template
                        v-if="
                          selectedProcess.activityId ===
                            'psyCrYXtKfcs-EJmrYIhv' ||
                          (selectedProcess.activityId ===
                            'psyCrYXtKfcs-EJmrYIhv' &&
                            !isPaymentFound)
                        "
                      >
                        <ValidationObserver ref="form" class="row">
                          <div class="col-auto">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="Total Payment"
                              :rules="paymentValidationRules"
                              class="col"
                            >
                              <NumberField
                                v-model="totalPayment"
                                label="Total Payment"
                                is-mandatory
                                class="q-pa-sm custom-controls"
                                :error="errors[0]"
                                @input="updateLoanPaymentAmount"
                              />
                            </ValidationProvider>
                          </div>
                          <div v-if="false" class="col-auto">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="Lumpsum Amount"
                              :rules="{
                                required: true,
                                min_value: 1,
                                max_value: 2000,
                              }"
                              class="col"
                            >
                              <NumberField
                                v-model="lumpsumAmount"
                                label="Lumpsum Amount"
                                is-mandatory
                                class="q-pa-sm custom-controls"
                                :max="2000"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                          <div class="col-auto">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="Payment Frequency"
                              :rules="{ required: true }"
                              class="col"
                            >
                              <SelectField
                                v-model="benefitType"
                                label="Payment Frequency"
                                is-mandatory
                                :options="benefitTypes"
                                class="q-pa-sm custom-controls"
                                :is-clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                          <div class="col-auto">
                            <BaseButton
                              label="Calculate"
                              class="payment-action custom-button q-ml-sm"
                              @click="paymentCalculation"
                            />
                          </div>
                        </ValidationObserver>
                      </template>
                    </div>
                    <div class="col-auto">
                      <div class="row">
                        <div
                          v-if="
                            selectedProcess.activityId ===
                              '90xxPehjko0dNYRjbX5wm' &&
                            isPaymentFound &&
                            hasOtherClaimsType
                          "
                          class="col-auto"
                        >
                          <BaseButton
                            label="Add Lumpsum Payment"
                            color="primary"
                            class="payment-action q-mr-sm custom-button"
                            @click="addLumpsumRow"
                          />
                        </div>
                        <template
                          v-if="
                            selectedProcess.activityId !==
                              '001f5CdkTDtjCDn3logTT' &&
                            selectedProcess.activityId !==
                              'szS6B-q1x9acpB83rP6jT'
                          "
                        >
                          <div class="col-auto">
                            <FormFieldLabel
                              label="Number of payments to approve"
                              class="payment-action"
                            />
                          </div>
                          <div class="col-auto">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="Number of payments"
                              :rules="{
                                min_value: 0,
                                max_value: paymentTableValues.length,
                              }"
                              class="col"
                            >
                              <NumberField
                                v-model="numberOfApprovePayment"
                                class="payment-action q-ml-sm custom-controls"
                                :is-clearable="false"
                                :is-readonly="!paymentTableValues.length"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                          <div class="col-auto">
                            <BaseButton
                              label="Payment Approve"
                              color="secondary"
                              class="payment-action q-ml-sm custom-button"
                              :disabled="!paymentTableValues.length"
                              @click="paymentApprove"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseButton
                              label="Clear"
                              color="red"
                              class="payment-action q-ml-sm custom-button"
                              :disabled="!paymentTableValues.length"
                              @click="clearPaymentApprove"
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col">
                  <BaseScrollbar>
                    <table
                      class="tab-table table-content q-mb-sm"
                      :class="paymentTableValues.length === 0 ? 'empty' : ''"
                    >
                      <thead>
                        <tr>
                          <!-- columns -->

                          <th
                            v-for="column in paymentTableColumns"
                            :key="column.id"
                          >
                            {{ column.label }}
                          </th>

                          <!-- ... -->

                          <th
                            v-if="
                              inbox &&
                              selectedProcess.activityId ===
                                '90xxPehjko0dNYRjbX5wm' &&
                              isPaymentFound &&
                              !hasLifeType &&
                              !hasCIClaimsType
                            "
                            class="actions"
                          >
                            <BaseActionButton
                              v-tooltip:top="'add row'"
                              color="primary"
                              icon="eva-plus"
                              no-border
                              class="q-ml-xs small"
                              size="16px"
                              @click="addPaymentRow"
                            />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(item, index) in paymentTableValues"
                          :key="'paymentrow' + index"
                        >
                          <template v-if="inbox">
                            <template v-if="item['edit']">
                              <td
                                v-for="column in paymentTableColumns"
                                :key="column.id"
                              >
                                <template
                                  v-if="column.type === 'SINGLE_SELECT'"
                                >
                                  <SelectField
                                    v-model="item[column.name]"
                                    :options="approvalTypes"
                                    :is-readonly="checkApprovalEdit(item)"
                                    @input="updateApprovalDate(index)"
                                  />
                                </template>
                                <template v-else-if="column.type === 'DATE'">
                                  <DateField
                                    v-model="item[column.name]"
                                    :minimum-validation="true"
                                    :is-readonly="true"
                                  />
                                </template>
                                <template v-else-if="column.type === 'NUMBER'">
                                  <NumberField
                                    v-model="item[column.name]"
                                    placeholder="0.00"
                                  />
                                </template>
                                <template
                                  v-else-if="column.type === 'SHORT_TEXT'"
                                >
                                  <TextField
                                    v-model="item[column.name]"
                                    :is-readonly="checkApprovalEdit(item)"
                                  />
                                </template>
                                <template
                                  v-else-if="column.type === 'CHECKBOX'"
                                >
                                  <CheckboxField
                                    v-model="item[column.name]"
                                    class="custom-controls"
                                    :is-disabled="checkApprovalEdit(item)"
                                  />
                                </template>
                                <template v-else>
                                  <slot
                                    :name="column.name"
                                    :value="item[column.name]"
                                    :item="item"
                                  >
                                    {{ item[column.name] }}
                                  </slot>
                                </template>
                              </td>
                            </template>
                            <template v-else>
                              <td
                                v-for="column in paymentTableColumns"
                                :key="column.id"
                              >
                                <template
                                  v-if="
                                    selectedProcess.activityId ===
                                      '90xxPehjko0dNYRjbX5wm' &&
                                    isPaymentFound &&
                                    item[column.name] !== '' &&
                                    !item['delete']
                                  "
                                >
                                  <template v-if="column.name === 'approval'">
                                    <template
                                      v-if="item[column.name] === 'Approved'"
                                    >
                                      <span
                                        :name="column.name"
                                        :value="item[column.name]"
                                        :item="item"
                                        class="text-green"
                                      >
                                        {{ item[column.name] }}
                                      </span>
                                    </template>
                                    <template v-else>
                                      <span
                                        :name="column.name"
                                        :value="item[column.name]"
                                        :item="item"
                                        class="text-red"
                                      >
                                        {{ item[column.name] }}
                                      </span>
                                    </template>
                                  </template>
                                  <template
                                    v-else-if="column.name === 'paymentDate'"
                                  >
                                    <DateField
                                      v-model="item[column.name]"
                                      :minimum-validation="true"
                                    />
                                  </template>
                                  <template
                                    v-else-if="column.type === 'CHECKBOX'"
                                  >
                                    <CheckboxField
                                      v-model="item[column.name]"
                                      class="custom-controls"
                                      :is-disabled="checkApprovalEdit(item)"
                                    />
                                  </template>
                                  <template v-else>
                                    <slot
                                      :name="column.name"
                                      :value="item[column.name]"
                                      :item="item"
                                    >
                                      {{ item[column.name] }}
                                    </slot>
                                  </template>
                                </template>
                                <template v-else>
                                  <template
                                    v-if="column.type === 'SINGLE_SELECT'"
                                  >
                                    <SelectField
                                      v-model="item[column.name]"
                                      :options="approvalTypes"
                                      :is-readonly="checkApprovalEdit(item)"
                                      @input="updateApprovalDate(index)"
                                    />
                                  </template>
                                  <template v-else-if="column.type === 'DATE'">
                                    <DateField
                                      v-model="item[column.name]"
                                      :minimum-validation="true"
                                      :is-readonly="true"
                                    />
                                  </template>
                                  <template
                                    v-else-if="
                                      column.name === 'baseAmount' &&
                                      item['delete']
                                    "
                                  >
                                    <NumberField
                                      v-model="item[column.name]"
                                      placeholder="0.00"
                                      :min="0"
                                      :max="2000"
                                      @input="calcOtherColumns(index)"
                                    />
                                  </template>
                                  <template
                                    v-else-if="column.type === 'SHORT_TEXT'"
                                  >
                                    <TextField
                                      v-model="item[column.name]"
                                      :is-readonly="checkApprovalEdit(item)"
                                    />
                                  </template>
                                  <template
                                    v-else-if="column.type === 'CHECKBOX'"
                                  >
                                    <CheckboxField
                                      v-model="item[column.name]"
                                      class="custom-controls"
                                      :is-disabled="checkApprovalEdit(item)"
                                    />
                                  </template>
                                  <template v-else>
                                    <slot
                                      :name="column.name"
                                      :value="item[column.name]"
                                      :item="item"
                                    >
                                      {{ item[column.name] }}
                                    </slot>
                                  </template>
                                </template>
                              </td>

                              <td
                                v-if="
                                  inbox &&
                                  selectedProcess.activityId ===
                                    '90xxPehjko0dNYRjbX5wm' &&
                                  isPaymentFound
                                "
                                class="actions"
                              >
                                <BaseActionButton
                                  v-if="item['delete']"
                                  v-tooltip="'delete row'"
                                  is-flat
                                  color="red"
                                  icon="eva-trash-outline"
                                  no-border
                                  class="q-ml-xs small"
                                  size="16px"
                                  @click="removePaymentRow(index)"
                                />
                              </td>
                            </template>
                          </template>
                          <template v-else>
                            <td
                              v-for="column in paymentTableColumns"
                              :key="column.id"
                            >
                              <template v-if="column.name === 'approval'">
                                <template
                                  v-if="item[column.name] === 'Approved'"
                                >
                                  <span
                                    :name="column.name"
                                    :value="item[column.name]"
                                    :item="item"
                                    class="text-green"
                                  >
                                    {{ item[column.name] }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span
                                    :name="column.name"
                                    :value="item[column.name]"
                                    :item="item"
                                    class="text-red"
                                  >
                                    {{ item[column.name] }}
                                  </span>
                                </template>
                              </template>
                              <template
                                v-else-if="column.name === 'paymentDate'"
                              >
                                <DateField
                                  v-model="item[column.name]"
                                  :minimum-validation="true"
                                  is-readonly
                                />
                              </template>
                              <template v-else-if="column.type === 'CHECKBOX'">
                                <CheckboxField
                                  v-model="item[column.name]"
                                  class="custom-controls"
                                  :is-disabled="true"
                                />
                              </template>
                              <template v-else>
                                <slot
                                  :name="column.name"
                                  :value="item[column.name]"
                                  :item="item"
                                >
                                  {{ item[column.name] }}
                                </slot>
                              </template>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>

                    <BaseButton
                      v-if="inbox && paymentTableValues.length"
                      label="Update Payments"
                      color="primary"
                      style="float: right"
                      @click="updatePaymentTable"
                    />
                  </BaseScrollbar>
                </div>
              </div>
            </template>
            <template v-else-if="tab === 'Payments'">
              <div v-if="paymentHistoryTotalItems" class="row">
                <div class="col">
                  <Pagination
                    class="custom-form-table"
                    :total-items="paymentHistoryTotalItems"
                    :current-page.sync="paymentHistoryCurrentPage"
                    :items-per-page.sync="paymentHistoryItemsPerPage"
                    @loadAll="loadAllPaymentHistory"
                  />
                </div>
                <div class="col-auto q-mt-xs q-ml-sm">
                  <ExpandableSearch
                    class="q-mr-sm"
                    @search="paymentHistorySearchRow"
                  />
                </div>
              </div>
              <BaseScrollbar height="330px">
                <table
                  class="tab-table q-mt-sm"
                  :class="!paymentHistoryTotalItems ? 'empty' : ''"
                >
                  <thead>
                    <tr>
                      <th
                        v-for="column in paymentHistoryColumns"
                        :key="column.id"
                      >
                        {{ column.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in paymentHistoryValues"
                      :key="'paymenthistoryrow' + index"
                    >
                      <td
                        v-for="column in paymentHistoryColumns"
                        :key="column.id"
                      >
                        {{ item[column.name] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BaseScrollbar>
            </template>
          </div>
        </div>
      </div>
    </BaseScrollbar>

    <!-- ... -->

    <Modal
      v-model="declineReasonModal"
      width="30vw"
      height="40vh"
      has-footer
      @input="declineReasonModal = false"
    >
      <!-- title -->

      <template #title>Recommend to Decline</template>

      <!-- ... -->

      <template #default>
        <ValidationObserver ref="declineModal">
          <div>
            <ValidationProvider
              v-slot="{ errors }"
              name="Decline Reason"
              :rules="{ required: true }"
              class="col"
            >
              <SelectField
                v-model="declineReason"
                label="Decline Reason"
                is-mandatory
                :options="declineReasonList"
                class="field"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="Cancel"
          class="q-mr-sm"
          @click="declineReasonModal = false"
        />
        <BaseButton
          label="Recommend to Decline"
          color="primary"
          @click="updateDeclineReason"
        />
      </template>

      <!-- ... -->
    </Modal>

    <Modal
      v-model="claimStatusModal"
      width="60vw"
      height="70vh"
      has-footer
      @input="claimStatusModal = false"
    >
      <!-- title -->

      <template #title
        >"{{ selectedProcess.requestNo }}" Claim Status Entry</template
      >

      <!-- ... -->

      <template #default>
        <div id="content-wrapper" class="claim-status">
          <ValidationObserver ref="claimStatusModal">
            <div class="row">
              <div class="col-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Claim Status"
                  :rules="{ required: true }"
                  class="col"
                >
                  <SelectField
                    v-model="claimStatus"
                    label="Claim Status"
                    is-mandatory
                    :options="claimStatusList"
                    class="field"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div class="col-4">
                <SelectField
                  v-model="csNote"
                  label="Note"
                  :options="csNoteList"
                  new-option
                  is-readonly
                  class="field"
                />
              </div>
              <div class="col-4">
                <SelectField
                  v-model="claimLetterSent"
                  label="Claim Letter Sent"
                  :options="claimLetterSentList"
                  new-option
                  is-readonly
                  class="field"
                />
              </div>
              <div class="col-4">
                <SelectField
                  v-model="mailingType"
                  label="Mailing Type"
                  :options="mailingTypeList"
                  class="field"
                />
              </div>
              <div class="col-4">
                <SelectField
                  v-model="lenderLetter"
                  label="Lender Letter"
                  :options="lenderLetterList"
                  new-option
                  is-readonly
                  class="field"
                />
              </div>
              <div class="col-4" style="margin: auto">
                <CheckboxField
                  v-model="internalOnly"
                  label="Internal Only"
                  class="field"
                />
              </div>
              <div class="col-12">
                <TextAreaField
                  v-model="internalNotes"
                  label="Internal Notes"
                  class="field"
                />
              </div>
            </div>
          </ValidationObserver>
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="Cancel"
          class="q-mr-sm"
          @click="claimStatusModal = false"
        />
        <BaseButton
          :label="Object.keys(claimStatusRow).length ? 'Update' : 'Add'"
          color="primary"
          @click="saveClaimStatus"
        />
      </template>

      <!-- ... -->
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
//import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import Tabs from "@/components/common/tabs/Tabs2.vue";
import Modal from "@/components/common/popup/Modal.vue";
import { exportData } from "@/helpers/export-excel-data";
import { axiosCrypto } from "@/api/axios.js";
import { form, custom } from "@/api/factory.js";
import Pagination from "@/components/common/display/Pagination.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "CustomProcessForm",

  components: {
    ValidationObserver,
    ValidationProvider,
    FormFieldLabel,
    SelectField,
    TextField,
    DateField,
    NumberField,
    TextAreaField,
    //SingleChoiceField,
    MultipleChoiceField,
    CheckboxField,
    Tabs,
    Modal,
    Pagination,
    ExpandableSearch,
  },

  props: {
    formId: {
      type: String,
      default: "0",
    },

    panels: {
      type: Array,
      default: () => [],
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formModel: {
      type: Object,
      default: () => {},
    },

    saveAction: {
      type: String,
      default: "",
    },

    workflowFormModel: {
      type: Object,
      default: () => {},
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    dataValidationValues: {
      type: Array,
      default: () => [],
    },

    selectedProcess: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    checkList: {
      type: Array,
      default: () => [],
    },

    formCheckList: {
      type: Boolean,
      default: false,
    },

    saveOnly: {
      type: Boolean,
      default: false,
    },

    inbox: {
      type: Boolean,
      default: false,
    },

    autoSaveModel: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      formWidth: "100%",
      claimantInfoList: [
        {
          id: this.$nano.id(),
          label: "Borrower",
          value: "Borrower",
          claimType: "",
        },
        {
          id: this.$nano.id(),
          label: "Co-Borrower",
          value: "Co-Borrower",
          claimType: "",
        },
        {
          id: this.$nano.id(),
          label: "Next of Kin",
          value: "Next of Kin",
          claimType: "Life",
        },
      ],
      authorizationTypes: [
        {
          id: this.$nano.id(),
          label: "Claimant Submitted",
          value: "Claimant Submitted",
        },
        {
          id: this.$nano.id(),
          label: "Special Auth",
          value: "Special Auth",
        },
        {
          id: this.$nano.id(),
          label: "Goodwill Claim",
          value: "Goodwill Claim",
        },
        {
          id: this.$nano.id(),
          label: "Advance Receipt",
          value: "Advance Receipt",
        },
        {
          id: this.$nano.id(),
          label: "Lump Sum Eligibility Status",
          value: "Lump Sum Eligibility Status",
        },
        {
          id: this.$nano.id(),
          label: "Pandemic",
          value: "Pandemic",
        },
        {
          id: this.$nano.id(),
          label: "Return To Work",
          value: "Return To Work",
        },
        {
          id: this.$nano.id(),
          label: "Prior Loan Used",
          value: "Prior Loan Used",
        },
      ],
      documentCheckList: [
        {
          id: this.$nano.id(),
          label: "Claimant's Statement",
          value: "Claimant's Statement",
          for: [
            "Critical Illness",
            "Disability",
            "Involuntary Unemployment",
            "Life",
            "Lifetime Milestone",
            "Sickness or Injury",
            "Unpaid Family Leave",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Application to Enroll",
          value: "Application to Enroll",
          for: [
            "Critical Illness",
            "Disability",
            "Involuntary Unemployment",
            "Life",
            "Lifetime Milestone",
            "Sickness or Injury",
            "Unpaid Family Leave",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Payment Schedule/History",
          value: "Payment Schedule or History",
          for: [
            "Critical Illness",
            "Disability",
            "Involuntary Unemployment",
            "Life",
            "Lifetime Milestone",
            "Sickness or Injury",
            "Unpaid Family Leave",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Employer's Statement/ROE",
          value: "Employer's Statement Or ROE",
          for: [
            "Critical Illness",
            "Involuntary Unemployment",
            "Sickness or Injury",
            "Unpaid Family Leave",
            "Disability",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Loan Agreement",
          value: "Loan Agreement",
          for: [
            "Critical Illness",
            "Disability",
            "Involuntary Unemployment",
            "Life",
            "Lifetime Milestone",
            "Sickness or Injury",
            "Unpaid Family Leave",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Lender Claim Statement",
          value: "Lender Claim Statement",
          for: [
            "Critical Illness",
            "Disability",
            "Involuntary Unemployment",
            "Life",
            "Lifetime Milestone",
            "Sickness or Injury",
            "Unpaid Family Leave",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Physician's Statement",
          value: "Physician's Statement",
          for: ["Critical Illness", "Life", "Sickness or Injury", "Disability"],
        },

        {
          id: this.$nano.id(),
          label: "Section 2B",
          value: "Section 2B",
          for: ["Lifetime Milestone"],
        },

        {
          id: this.$nano.id(),
          label: "Supporting Documentation",
          value: "Supporting Documentation",
          for: ["Lifetime Milestone"],
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "Claim Status",
          value: "Claim Status",
        },
        {
          id: this.$nano.id(),
          label: "Daily Data Summary",
          value: "Daily Data Summary",
        },
        {
          id: this.$nano.id(),
          label: "Benefit Payment",
          value: "Benefit Payment",
        },
        {
          id: this.$nano.id(),
          label: "Payments",
          value: "Payments",
        },
      ],
      tab: "Claim Status",
      totalPayment: 0,
      lumpsumAmount: 0,
      benefitType: "",
      benefitTypes: [
        {
          id: this.$nano.id(),
          label: "Monthly",
          value: "Monthly",
        },
        {
          id: this.$nano.id(),
          label: "Bi-weekly",
          value: "BiWeekly",
        },
        {
          id: this.$nano.id(),
          label: "Semi-monthly",
          value: "SemiMonthly",
        },
        {
          id: this.$nano.id(),
          label: "Weekly",
          value: "Weekly",
        },
      ],
      numberOfApprovePayment: 0,
      paymentTableColumns: [
        {
          id: this.$nano.id(),
          name: "paymentNumber",
          label: "Payment Number",
          type: "LABEL",
        },
        {
          id: this.$nano.id(),
          name: "baseAmount",
          label: "Payment Base Amount",
          type: "NUMBER",
        },
        {
          id: this.$nano.id(),
          name: "claimAmount",
          label: "Claim Amount (20% Increased)",
          type: "NUMBER",
        },
        {
          id: this.$nano.id(),
          name: "cumulativeTotal",
          label: "Cumulative Total",
          type: "NUMBER",
        },
        {
          id: this.$nano.id(),
          name: "approval",
          label: "Approval",
          type: "SINGLE_SELECT",
        },
        {
          id: this.$nano.id(),
          name: "approvedDate",
          label: "Approved Date",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "paymentDate",
          label: "Payment Date",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "approvedBy",
          label: "Approved By",
          type: "LABEL",
        },
        {
          id: this.$nano.id(),
          name: "notes",
          label: "Notes",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "goodWill",
          label: "Goodwill",
          type: "CHECKBOX",
        },
        {
          id: this.$nano.id(),
          name: "lppPlus",
          label: "LPP Plus",
          type: "CHECKBOX",
        },
        {
          id: this.$nano.id(),
          name: "preApprovedPmt",
          label: "Pre Approved Pmt",
          type: "CHECKBOX",
        },
        {
          id: this.$nano.id(),
          name: "psgCovered",
          label: "PSG Covered",
          type: "CHECKBOX",
        },
      ],
      paymentTableValues: [],
      isPaymentFound: false,
      approvalTypes: [
        {
          id: this.$nano.id(),
          label: "Approve",
          value: "Approved",
        },
        {
          id: this.$nano.id(),
          label: "Cancel",
          value: "Cancelled",
        },
      ],
      cityList: [],
      provinceList: [],
      productList: [
        {
          id: this.$nano.id(),
          label: "Critical Illness",
          value: "Critical Illness",
        },
        {
          id: this.$nano.id(),
          label: "Disability",
          value: "Disability",
        },
        {
          id: this.$nano.id(),
          label: "Involuntary Unemployment",
          value: "Involuntary Unemployment",
        },
        {
          id: this.$nano.id(),
          label: "Life",
          value: "Life",
        },
        {
          id: this.$nano.id(),
          label: "Lifetime Milestone",
          value: "Lifetime Milestone",
        },
        {
          id: this.$nano.id(),
          label: "Sickness or Injury",
          value: "Sickness or Injury",
        },
        {
          id: this.$nano.id(),
          label: "Unpaid Family Leave",
          value: "Unpaid Family Leave",
        },
      ],
      declineReasonList: [],
      declineReason: "",
      declineReasonModal: false,
      loanInfoVerify: false,
      claimInfoVerify: false,
      languageList: [
        {
          id: this.$nano.id(),
          label: "EN",
          value: "EN",
        },
        {
          id: this.$nano.id(),
          label: "FR",
          value: "FR",
        },
      ],
      isNameOfDeceasedEditable: false,
      claimStatusColumns: [
        {
          id: this.$nano.id(),
          name: "ClaimStatus",
          label: "Claim Status",
        },
        {
          id: this.$nano.id(),
          name: "CSDate",
          label: "Date",
        },
        {
          id: this.$nano.id(),
          name: "CSNote",
          label: "Note",
        },
        {
          id: this.$nano.id(),
          name: "ClaimLetterSent",
          label: "Claim Letter Sent",
        },
        {
          id: this.$nano.id(),
          name: "AuthorizedBy",
          label: "Authorized By",
        },
        {
          id: this.$nano.id(),
          name: "MailingType",
          label: "Mailing Type",
        },
        {
          id: this.$nano.id(),
          name: "ModifiedBy",
          label: "Modified By",
        },
        {
          id: this.$nano.id(),
          name: "ModifiedDt",
          label: "Modified Date",
        },
        {
          id: this.$nano.id(),
          name: "LenderLetter",
          label: "Lender Letter",
        },
        {
          id: this.$nano.id(),
          name: "CreatedBy",
          label: "Created By",
        },
        {
          id: this.$nano.id(),
          name: "CreatedDt",
          label: "Created Date",
        },
        {
          id: this.$nano.id(),
          name: "InternalOnly",
          label: "Internal Only",
        },
        {
          id: this.$nano.id(),
          name: "InternalNotes",
          label: "Internal Notes",
        },
      ],
      claimStatusValues: [],
      claimStatusValuesData: [],
      claimStatusTotalItems: 0,
      claimStatusItemsPerPage: 10,
      claimStatusCurrentPage: 1,
      claimStatusRow: {},
      claimStatusModal: false,
      claimStatusList: [],
      claimStatus: "",
      csDate: "",
      csNoteList: [],
      csNote: 0,
      claimLetterSentList: [],
      claimLetterSent: 0,
      mailingTypeList: [
        {
          id: this.$nano.id(),
          label: "Word Merged Letter",
          value: "WordmergedLetter",
        },
        {
          id: this.$nano.id(),
          label: "Email",
          value: "Email",
        },
      ],
      mailingType: "",
      lenderLetterList: [],
      lenderLetter: "",
      internalOnly: false,
      internalNotes: "",
      dailyDataStatusColumns: [
        {
          id: this.$nano.id(),
          name: "FileDate",
          label: "File Date",
        },
        {
          id: this.$nano.id(),
          name: "PolicyNumber",
          label: "Policy Number",
        },
        {
          id: this.$nano.id(),
          name: "Province",
          label: "Province",
        },
        {
          id: this.$nano.id(),
          name: "FirstName",
          label: "First Name",
        },
        {
          id: this.$nano.id(),
          name: "LastName",
          label: "Last Name",
        },
        {
          id: this.$nano.id(),
          name: "LoanKey",
          label: "Loan Key",
        },
        {
          id: this.$nano.id(),
          name: "PriorLoan",
          label: "Prior Loan",
        },
        {
          id: this.$nano.id(),
          name: "EffectiveDate",
          label: "Effective Date",
        },
        {
          id: this.$nano.id(),
          name: "CancellationDate",
          label: "Cancellation Date",
        },
        {
          id: this.$nano.id(),
          name: "LoanTerm",
          label: "Loan Term",
        },
        {
          id: this.$nano.id(),
          name: "PrincipalLent",
          label: "Principal Lent",
        },
        {
          id: this.$nano.id(),
          name: "Freq",
          label: "Freq",
        },
        {
          id: this.$nano.id(),
          name: "TotalObligation",
          label: "Total Obligation",
        },
        {
          id: this.$nano.id(),
          name: "FirstPayment",
          label: "First Payment",
        },
        {
          id: this.$nano.id(),
          name: "MinimumPrincipal",
          label: "Minimum Principal",
        },
        {
          id: this.$nano.id(),
          name: "MinimumInterest",
          label: "Minimum Interest",
        },
        {
          id: this.$nano.id(),
          name: "MinPIDue",
          label: "Min PI Due",
        },
        {
          id: this.$nano.id(),
          name: "LPPCharge",
          label: "LPP Charge",
        },
        {
          id: this.$nano.id(),
          name: "LPPCollected",
          label: "LPP Collected",
        },
        {
          id: this.$nano.id(),
          name: "LastPayment",
          label: "Last Payment",
        },
        {
          id: this.$nano.id(),
          name: "DefaultDate",
          label: "Default Date",
        },
        {
          id: this.$nano.id(),
          name: "ClearedPayments",
          label: "Cleared Payments",
        },
        {
          id: this.$nano.id(),
          name: "MissedPayments",
          label: "Missed Payments",
        },
        {
          id: this.$nano.id(),
          name: "PastDueAmount",
          label: "Past Due Amount",
        },
        {
          id: this.$nano.id(),
          name: "PastDueInterest",
          label: "Past Due Interest",
        },
        {
          id: this.$nano.id(),
          name: "PastDueInsuranceFee",
          label: "Past Due Insurance Fee",
        },
        {
          id: this.$nano.id(),
          name: "PastDuePrincipalAmount",
          label: "Past Due Principal Amount",
        },
        {
          id: this.$nano.id(),
          name: "PastDueNsfFee",
          label: "Past Due Nsf Fee",
        },
        {
          id: this.$nano.id(),
          name: "LoanBalance",
          label: "Loan Balance",
        },
        {
          id: this.$nano.id(),
          name: "PrincipalBalance",
          label: "Principal Balance",
        },
        {
          id: this.$nano.id(),
          name: "ExpectedMinPayment",
          label: "Expected Min Payment",
        },
      ],
      dailyDataStatusValues: [],
      dailyDataStatusValuesData: [],
      dailyDataStatusTotalItems: 0,
      dailyDataStatusItemsPerPage: 10,
      dailyDataStatusCurrentPage: 1,
      paymentHistoryColumns: [
        {
          id: this.$nano.id(),
          name: "StoreLoanNumber",
          label: "Store Loan Number",
        },
        {
          id: this.$nano.id(),
          name: "Premium",
          label: "Premium",
        },
        {
          id: this.$nano.id(),
          name: "PaymentDate",
          label: "Payment Date",
        },
        {
          id: this.$nano.id(),
          name: "TransactionType",
          label: "Transaction Type",
        },
        {
          id: this.$nano.id(),
          name: "Taxes",
          label: "Taxes",
        },
        {
          id: this.$nano.id(),
          name: "PaymentAmount",
          label: "Payment Amount",
        },
        {
          id: this.$nano.id(),
          name: "RemitDate",
          label: "Remit Date",
        },
        // {
        //   id: this.$nano.id(),
        //   name: "PaymentID",
        //   label: "Payment Id",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "ImportDate",
        //   label: "Import Date",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Store#",
        //   label: "Store#",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Loan#",
        //   label: "Loan#",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "PmtFrq",
        //   label: "Pmt Frq",
        // },

        // {
        //   id: this.$nano.id(),
        //   name: "Comments",
        //   label: "Comments",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "ImportNotes",
        //   label: "Import Notes",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "stamp",
        //   label: "stamp",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "IgnoreCancel",
        //   label: "Ignore Cancel",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "FirstYearPremium",
        //   label: "First Year Premium",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "RenewalPremium",
        //   label: "Renewal Premium",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "DaysElapsed",
        //   label: "Days Elapsed",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "FirstYearPremiumOrRenewal",
        //   label: "First Year Premium Or Renewal",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "ImportFilename",
        //   label: "Import File name",
        // },
      ],
      paymentHistoryValues: [],
      paymentHistoryValuesData: [],
      paymentHistoryTotalItems: 0,
      paymentHistoryItemsPerPage: 10,
      paymentHistoryCurrentPage: 1,
      clearPayment: false,
      hasLifeType: false,
      hasCIClaimsType: false,
      hasLMUFLClaimsType: false,
      hasOtherClaimsType: false,
      isChangeBenefitClaimed: false,
      hasSicknessClaimsChanged: false,
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100%";
    },

    fieldVerification() {
      let verify = false;
      if (this.dataValidationValues.length) {
        let updateFields = [
          "First Name",
          "Last Name",
          "DOB",
          "Address",
          "City",
          "Province",
          "Postal Code",
          "Phone No.",
          "Email ID",
        ];
        updateFields.forEach((rowField) => {
          let field = this.dataValidationValues.find(
            (row) => row.fieldName == rowField
          );
          if (field) {
            if (
              field.status === "Not Matched" ||
              field.status === "Not Verified"
            ) {
              verify = true;
            }
          } else {
            verify = true;
          }
        });
      } else {
        verify = true;
      }
      return verify;
    },

    userInitials() {
      let initials = "";
      if (this.$store.state.session.firstName) {
        initials += this.$store.state.session.firstName[0].toUpperCase();
      }
      if (this.$store.state.session.lastName) {
        initials += this.$store.state.session.lastName[0].toUpperCase();
      } else {
        initials += this.$store.state.session.firstName[1];
      }
      return initials;
    },

    userNames() {
      let username;
      if (this.$store.state.session.firstName) {
        username = this.$store.state.session.firstName;
      }
      if (this.$store.state.session.lastName) {
        username += " " + this.$store.state.session.lastName;
      }
      return username;
    },

    _productList() {
      let product = this.formFields.find(
        (field) => field.label === "Benefit Claimed"
      );
      let benefitClaim = "";
      if (product) {
        benefitClaim = this.formModel[product.value];
      }
      if (
        benefitClaim === "Critical Illness" ||
        benefitClaim === "Sickness or Injury"
      ) {
        return this.productList.filter(
          (option) =>
            option.label === "Critical Illness" ||
            option.label === "Sickness or Injury"
        );
      }
      return this.productList;
    },

    _documentCheckList() {
      let product = this.formFields.find(
        (field) => field.label === "Benefit Claimed"
      );
      let benefitClaim = "";
      if (product) {
        benefitClaim = this.formModel[product.value];
      }
      return this.documentCheckList.filter((option) =>
        option.for.includes(benefitClaim)
      );
    },

    STLOCTypeFormula() {
      let policy = this.formFields.find(
        (field) => field.label === "Group Policy"
      );
      let number = "";
      if (policy) {
        number = this.formModel[policy.value];
      }

      let num = number.split("-");

      // console.log(num);
      if (num.length) {
        if ((num[0] === "ST001" && num[1] !== "MM01") || num[0] === "LOC001") {
          return true;
        }
      }
      return false;
    },

    ENHTypeFormula() {
      let policy = this.formFields.find(
        (field) => field.label === "Group Policy"
      );
      let number = "";
      if (policy) {
        number = this.formModel[policy.value];
      }

      let num = number.split("-");

      // console.log(num);
      if (num.length) {
        if (num[0] === "ENH001") {
          return true;
        }
      }
      return false;
    },

    mobileFinanceFormula() {
      let policy = this.formFields.find(
        (field) => field.label === "Group Policy"
      );
      let number = "";
      if (policy) {
        number = this.formModel[policy.value];
      }

      let num = number.split("-");

      // console.log(num);
      if (num.length) {
        if (num[0] === "CPL001M") {
          return true;
        }
      }
      return false;
    },

    paymentValidationRules() {
      const rules = {};
      rules.required = true;

      rules.min_value = 1;
      if (this.STLOCTypeFormula && !this.hasLMUFLClaimsType) {
        rules.max_value = 4000;
      } else if (this.STLOCTypeFormula && this.hasLMUFLClaimsType) {
        rules.max_value = 2000;
      } else if (
        this.ENHTypeFormula &&
        this.hasOtherClaimsType &&
        this.getModel("Payment Frequency") === "Monthly"
      ) {
        // &&
        //this.getModel("Payment Frequency") === "Monthly"
        rules.max_value = 1000;
      } else if (
        this.mobileFinanceFormula &&
        this.hasOtherClaimsType &&
        this.getModel("Payment Frequency") === "Monthly"
      ) {
        rules.max_value = 1250;
      } else if (this.ENHTypeFormula || this.mobileFinanceFormula) {
        rules.max_value = 1000;
      }

      return rules;
    },

    moneyMartFormula() {
      let policy = this.formFields.find(
        (field) => field.label === "Group Policy"
      );
      let number = "";
      if (policy) {
        number = this.formModel[policy.value];
      }

      let num = number.split("-");

      // console.log(num);
      if (num.length) {
        if (num[0] === "ST001" && num[1] === "MM01") {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    saveAction: {
      immediate: true,
      async handler() {
        if (this.saveAction) {
          if (this.saveAction === "Save" || this.saveAction === "Force Close") {
            this.$emit("update:workflowFormModel", this.formModel);
            return;
          }
          // const isValid = await this.$refs.form.validate();

          // if (!isValid) {
          //   this.$alert.warning("Required Mandatory Info");
          //   this.$emit("update:saveAction", "");
          //   return;
          // }

          if (
            this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv" &&
            this.saveAction == "Recommend to Approve"
          ) {
            if (
              this.fieldVerification ||
              !this.loanInfoVerify ||
              !this.claimInfoVerify
            ) {
              this.$alert.warning("Verification Required");
              this.$emit("update:saveAction", "");
              return;
            }
            this.updateModel(this.$day.newDate(), "Processing Date");
            this.updateModel("Recommend to Approve", "Claim Decision");
          } else if (
            this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv" &&
            this.saveAction == "Recommend to Decline"
          ) {
            if (!this.getModel("Decline Reason")) {
              //this.$alert.warning("Decline Reason Required");
              this.declineReasonModal = true;
              this.$emit("update:saveAction", "");
              return;
            }
            this.updateModel(this.$day.newDate(), "Processing Date");
            this.updateModel("Recommend to Decline", "Claim Decision");
          } else if (
            this.selectedProcess.activityId === "k4-junYNP5tico7rUVJY_" ||
            this.selectedProcess.activityId === "pyEp-anz7DIWhvoQLf6oC"
          ) {
            let hasApprove = this.paymentTableValues.filter(
              (table) => table.approval
            );

            console.log(hasApprove);
            if (!hasApprove.length) {
              this.$alert.warning("Should be approve payment");
              this.$emit("update:saveAction", "");
              return;
            }
          }
          this.$emit("update:workflowFormModel", this.formModel);
        }
      },
    },

    dataValidationValues: {
      deep: true,
      immediate: true,
      handler() {
        this.loanInfoVerify = false;
        this.claimInfoVerify = false;
        if (this.dataValidationValues.length) {
          let loanVerify = this.dataValidationValues.find(
            (row) => row.fieldName === "Loan Info"
          );
          if (loanVerify) {
            this.loanInfoVerify =
              loanVerify.status === "Verified" ? true : false;
          }
          let claimVerify = this.dataValidationValues.find(
            (row) => row.fieldName === "Claim Info"
          );
          if (claimVerify) {
            this.claimInfoVerify =
              claimVerify.status === "Verified" ? true : false;
          }
        }
      },
    },

    selectedProcess: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedProcess.processId) {
          this.isChangeBenefitClaimed = false;
          this.clearPayment = false;
          this.getPaymentTable();
          this.getClaimStatus();
          this.getDailyDataStatus();
          this.getPaymentHistory();
          this.checkDocument();
        }
      },
    },

    formCheckList: {
      immediate: true,
      deep: true,
      handler() {
        if (this.formCheckList) {
          let origin = location.origin;
          if (
            (origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net" ||
              origin === "http://localhost:8080" ||
              (origin === "https://trial.ezofis.com" &&
                this.$store.state.session.tenantId === 12)) &&
            this.checkList.length
          ) {
            this.documentValidation();
            this.$emit("update:formCheckList", false);
          }
        }
      },
    },

    formModel: {
      // deep: true,
      immediate: true,
      handler() {
        if (this.formModel) {
          this.cloneFormModel = cloneDeep(this.formModel);
          this.getCalculateValidation(this.getModel("Benefit Claimed"));
        }
      },
    },

    moneyMartFormula: {
      immediate: true,
      handler() {
        if (this.moneyMartFormula) {
          this.paymentTableColumns.forEach((column) => {
            if (column.label === "Claim Amount (20% Increased)") {
              column.label = "Benefit Enhancement";
              return;
            }
          });
        } else {
          this.paymentTableColumns.forEach((column) => {
            if (column.label === "Benefit Enhancement") {
              column.label = "Claim Amount (20% Increased)";
              return;
            }
          });
        }
      },
    },

    hasSicknessClaimsChanged: {
      handler() {
        if (this.isPaymentFound && this.hasSicknessClaimsChanged) {
          if (this.paymentTableValues.length) {
            let firstRow = this.paymentTableValues[0];

            this.paymentTableValues = [];
            if (firstRow) {
              this.paymentTableValues.push(firstRow);
            }
          }
          let secondRow = {
            paymentId: 0,
            paymentNumber: this.paymentTableValues.length + 1,
            baseAmount: "",
            claimAmount: 0,
            cumulativeTotal: 0,
            approval: "",
            approvedDate: "",
            paymentDate: "",
            approvedBy: "",
            notes: "",
            goodWill: false,
            lppPlus: false,
            preApprovedPmt: false,
            psgCovered: false,
            benefitType: this.benefitType,
            delete: true,
          };
          this.paymentTableValues.push(secondRow);
        } else if (!this.isPaymentFound && this.hasSicknessClaimsChanged) {
          // console.log("this.isPaymentFound");
          let row = {
            paymentId: 0,
            paymentNumber: 1,
            baseAmount: "",
            claimAmount: 0,
            cumulativeTotal: 0,
            approval: "",
            approvedDate: "",
            paymentDate: "",
            approvedBy: "",
            notes: "",
            goodWill: false,
            lppPlus: false,
            preApprovedPmt: false,
            psgCovered: false,
            benefitType: this.benefitType,
            delete: true,
          };
          if (!this.paymentTableValues.length) {
            this.paymentTableValues = [];

            this.paymentTableValues.push(row);
          } else {
            let firstRow = this.paymentTableValues[0];

            this.paymentTableValues = [];

            this.paymentTableValues.push(firstRow);
            this.paymentTableValues.push(row);
          }
        }
      },
    },

    hasCIClaimsType: {
      immediate: true,
      handler() {
        if (this.hasCIClaimsType) {
          if (!this.isPaymentFound) {
            this.getLifeTypePaymentTable();
          }
        }
      },
    },

    hasLifeType: {
      immediate: true,
      handler() {
        if (this.hasLifeType) {
          if (!this.isPaymentFound) {
            this.getLifeTypePaymentTable();
          }
        }
      },
    },
  },

  created() {
    //this.getCityList();
    this.getProvinceList();
    this.getDeclineReasonList();
    this.getClaimStatusMaster(6);
  },

  mounted() {
    this.$watch(
      (vm) => [vm.claimStatusCurrentPage, vm.claimStatusItemsPerPage],
      () => this.getClaimStatus(),
      { deep: true, immediate: true }
    );

    this.$watch(
      (vm) => [vm.dailyDataStatusCurrentPage, vm.dailyDataStatusItemsPerPage],
      () => this.getDailyDataStatus(),
      { deep: true, immediate: true }
    );

    this.$watch(
      (vm) => [vm.paymentHistoryCurrentPage, vm.paymentHistoryItemsPerPage],
      () => this.getPaymentHistory(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    getCalculateValidation(value) {
      if (value === "Life") {
        this.hasLifeType = true;
        this.hasCIClaimsType = false;
        this.hasLMUFLClaimsType = false;
        this.hasOtherClaimsType = false;
      } else if (value === "Critical Illness") {
        this.hasCIClaimsType = true;
        this.hasLifeType = false;
        this.hasLMUFLClaimsType = false;
        this.hasOtherClaimsType = false;
      } else if (
        value === "Lifetime Milestone" ||
        value === "Unpaid Family Leave"
      ) {
        this.hasLMUFLClaimsType = true;
        this.hasCIClaimsType = false;
        this.hasLifeType = false;
        this.hasOtherClaimsType = false;
        //this.getPaymentTable();
      } else {
        this.hasOtherClaimsType = true;
        this.hasLifeType = false;
        this.hasCIClaimsType = false;
        this.hasLMUFLClaimsType = false;
        //this.getPaymentTable();
      }
    },

    getModel(name) {
      let field = this.formFields.find((row) => row.label === name);
      if (field) {
        if (name === "Benefit Claimed") {
          if (
            this.formModel[field.value] === "Critical Illness" ||
            this.formModel[field.value] === "Sickness or Injury"
          ) {
            this.isChangeBenefitClaimed = true;
          } else {
            this.isChangeBenefitClaimed = false;
          }
        } else if (name === "Current Age") {
          let dobField = this.formFields.find((row) => row.label === "DOB");
          if (dobField && this.formModel[dobField.value]) {
            this.formModel[field.value] = this.calculateAge(
              this.formModel[dobField.value]
            );
          }
        }
        if (field.type === "MULTIPLE_CHOICE") {
          if (this.formModel[field.value]) {
            return this.formModel[field.value];
          }
          return [];
        } else if (field.type === "DATE") {
          return this.$day.parseDate(this.formModel[field.value]);
        }
        return this.formModel[field.value];
      }
    },

    updateModel(value, name) {
      if (this.getModel(name) === "Sickness or Injury") {
        if (value === "Critical Illness") {
          this.hasSicknessClaimsChanged = true;
        } else {
          //this.hasSicknessClaimsChanged = false;
        }
      } else if (this.getModel(name) === "Critical Illness") {
        if (value === "Sickness or Injury") {
          this.isPaymentFound = false;
          this.hasSicknessClaimsChanged = false;

          this.paymentTableValues = [];
        }
      }
      let field = this.formFields.find((row) => row.label === name);
      if (field) {
        this.formModel[field.value] = value;
      }
      if (name === "Claimant Info") {
        if (value === "Next of Kin") {
          this.isNameOfDeceasedEditable = true;
        } else {
          this.isNameOfDeceasedEditable = false;
          this.getContractData(value);
        }
      } else if (name === "DOB") {
        let currentAgeField = this.formFields.find(
          (row) => row.label === "Current Age"
        );
        if (currentAgeField) {
          this.formModel[currentAgeField.value] = this.calculateAge(value);
        }
      } else if (name === "Loan Payment Amount") {
        this.totalPayment = value;
        if (this.benefitType) {
          this.paymentCalculation();
        }
      } else if (name === "Claim Date") {
        let ageAtDocField = this.formFields.find(
          (row) => row.label === "Age at Doc"
        );
        let claimDateField = this.formFields.find(
          (row) => row.label === "Claim Date"
        );
        if (ageAtDocField) {
          if (this.formModel[claimDateField.value]) {
            this.formModel[ageAtDocField.value] = this.calculateAgeAtDoc(
              value,
              this.$day.parseDate(this.formModel[claimDateField.value])
            );
          }
        }
      } else if (name === "Benefit Claimed") {
        this.getCalculateValidation(value);
        this.checkDocument();
      }
    },

    updateLoanPaymentAmount(value) {
      this.updateModel(value, "Loan Payment Amount");
    },

    checkHighlight(fieldName) {
      if (this.dataValidationValues.length) {
        let status = this.dataValidationValues.find(
          (row) => row.fieldName == fieldName
        )?.status;
        if (status === "Matched" || status === "Verified") {
          return "green";
        } else if (
          status === "" ||
          status === "Not Verified" ||
          status === "Partially Matched"
        ) {
          return "orange";
        } else if (status === "Not Matched") {
          return "red";
        }
      }
      return "";
    },

    calculateAge(birthDate) {
      if (!birthDate) return;

      const currentDate = new Date();
      if (new Date(birthDate) > currentDate) {
        return "";
      }

      const diffTime = currentDate - new Date(birthDate);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return Math.floor(totalDays / 365.25);
    },

    calculateAgeAtDoc(birthDate, claimDate) {
      if (!birthDate) return;
      if (new Date(birthDate) > new Date(claimDate)) {
        return "";
      }
      const diffTime = new Date(claimDate) - new Date(birthDate);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return Math.floor(totalDays / 365.25);
    },

    checkDocument() {
      let list = this.checkList.filter((doc) => {
        if (doc.conditions) {
          if (doc.conditions.length) {
            doc.required = false;
            doc.conditions.forEach((condition) => {
              if (doc.groupLogic === "ANY" && doc.required) {
                return;
              }
              let fieldType =
                this.formFields.find((field) => field.value === condition.name)
                  ?.type || "";
              let conditionValue = condition.value;
              let fieldValue = this.formModel[condition.name];
              if (fieldType === "NUMBER") {
                conditionValue = Number(conditionValue);
                fieldValue = Number(fieldValue);
              }
              switch (condition.logic) {
                case "IS_EQUALS_TO":
                  if (fieldValue === conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_NOT_EQUALS_TO":
                  if (fieldValue !== conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "CONTAINS":
                  if (fieldValue.indexOf(conditionValue) > -1) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN":
                  if (fieldValue > conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN_OR_EQUALS_TO":
                  if (fieldValue >= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN":
                  if (fieldValue < conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN_OR_EQUALS_TO":
                  if (fieldValue <= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
              }
            });
            if (doc.required || doc.visible) {
              doc.show = true;
            } else {
              doc.show = false;
            }
          } else {
            doc.show = true;
          }
        } else {
          doc.show = true;
        }
        return doc;
      });
      this.$emit("update:checkList", list);
    },

    documentValidation() {
      let field = this.formFields.find(
        (row) => row.label === "Required Documents"
      );
      if (field) {
        if (typeof this.formModel[field.value] === "string") {
          this.formModel[field.value] = [];
        }
        let attachDocs = this.checkList.filter((doc) => doc.attach);
        if (attachDocs.length) {
          attachDocs.forEach((doc) => {
            if (this.formModel[field.value].indexOf(doc.name) === -1) {
              this.formModel[field.value].push(doc.name);
            }
          });
        }
        this.autoSave("Required Documents");
      }
    },

    autoSave(name) {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "http://localhost:80801"
      ) {
        if (!this.saveOnly) {
          let field = this.formFields.find((row) => row.label === name);
          if (field) {
            let updateModel = {
              [field.value]: this.formModel[field.value],
            };
            this.$emit("update:autoSaveModel", updateModel);
          }
          console.log(name);
          if (this.formModel[field.value] != this.cloneFormModel[field.value]) {
            this.$emit("update:saveOnly", true);
            this.$emit("update:saveAction", "Save");
            this.cloneFormModel = cloneDeep(this.formModel);
          }
        }
      }
    },

    async updateDeclineReason() {
      const isValid = await this.$refs.declineModal.validate();
      if (!isValid) {
        return;
      }
      this.declineReasonModal = false;
      this.updateModel(this.declineReason, "Decline Reason");
      this.updateModel("Recommend to Decline", "Claim Decision");
      this.$emit("update:workflowFormModel", this.formModel);
      this.$emit("update:saveAction", "Recommend to Decline");
    },

    async getCityList() {
      this.cityList = [];
      let field = this.formFields.find((row) => row.label === "City");
      if (field) {
        try {
          const response = await axiosCrypto.post(
            `/form/${this.formId}/uniqueColumnValues`,
            JSON.stringify({
              column: field.value,
              keyword: "",
              rowFrom: 0,
              rowCount: 0, // + 10,
            })
          );
          const { status, data } = response;
          if (status !== 200) {
            throw response;
          }
          const options = JSON.parse(data);
          if (options.length) {
            this.cityList = options.map((option) => ({
              id: this.$nano.id(),
              label: option,
              value: option,
            }));
          }
          let optVal = this.cityList.find(
            (row) => row.label === this.formModel[field.value]
          );
          if (!optVal) {
            this.cityList.push({
              id: this.$nano.id(),
              label: this.formModel[field.value],
              value: this.formModel[field.value],
            });
          }
        } catch (e) {
          console.error(e);
          this.$alert.error("Error fetching options");
        }
      }
    },

    async getProvinceList() {
      this.provinceList = [];
      let field = this.formFields.find((row) => row.label === "Province");
      if (field) {
        try {
          const response = await axiosCrypto.post(
            `/form/${this.formId}/uniqueColumnValues`,
            JSON.stringify({
              column: field.value,
              keyword: "",
              rowFrom: 0,
              rowCount: 0, // + 10,
            })
          );
          const { status, data } = response;
          if (status !== 200) {
            throw response;
          }
          const options = JSON.parse(data);
          if (options.length) {
            this.provinceList = options.map((option) => ({
              id: this.$nano.id(),
              label: option,
              value: option,
            }));
          }
          let optVal = this.provinceList.find(
            (row) => row.label === this.formModel[field.value]
          );
          if (!optVal) {
            this.provinceList.push({
              id: this.$nano.id(),
              label: this.formModel[field.value],
              value: this.formModel[field.value],
            });
          }
        } catch (e) {
          console.error(e);
          this.$alert.error("Error fetching options");
        }
      }
    },

    async getDeclineReasonList() {
      this.declineReasonList = [];
      const { error, payload } = await form.getFormEntries(5, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [],
        itemsPerPage: 0,
        currentPage: 0,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data } = payload;

      this.declineReasonList = data[0].value.map((entry) => ({
        id: entry.itemid,
        label: `${entry["_siZd2ju1yrpaAcCiiJ8X"]} - ${entry["xvC46pmC5uL77kIpjJ2p-"]}`,
        value: `${entry["_siZd2ju1yrpaAcCiiJ8X"]} - ${entry["xvC46pmC5uL77kIpjJ2p-"]}`,
      }));
    },

    async getDataValidation() {
      const { error, payload } = await custom.armgroupMLValidation({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.selectedProcess.processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.$emit("updateDataValidationValues", payload);
      } else {
        this.$emit("updateDataValidationValues", []);
      }
    },

    async confirmCustomerInfo() {
      let updateFields = [
        "First Name",
        "Last Name",
        "DOB",
        "Address",
        "City",
        "Province",
        "Postal Code",
        "Phone No.",
        "Email ID",
      ];
      let newFields = [],
        changeFields = [];

      updateFields.forEach((rowField) => {
        let field = this.dataValidationValues.find(
          (row) => row.fieldName == rowField
        );
        if (field) {
          field.formValue = this.getModel(rowField);
          field.status =
            field.status === "Not Matched" || field.status === "Not Verified"
              ? "Verified"
              : field.status;
          changeFields.push(field);
        } else {
          newFields.push({
            processId: this.selectedProcess.processId,
            fieldName: rowField,
            formValue: this.getModel(rowField),
            status: "Verified",
          });
        }
      });

      if (changeFields.length) {
        const { error } = await custom.updateArmgroupMLValidation(changeFields);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }
      if (newFields.length) {
        const { error } = await custom.insertArmgroupMLValidation(newFields);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      this.getDataValidation();
    },

    async unConfirmCustomerInfo() {
      let updateFields = [
        "First Name",
        "Last Name",
        "DOB",
        "Address",
        "City",
        "Province",
        "Postal Code",
        "Phone No.",
        "Email ID",
      ];
      if (this.dataValidationValues.length) {
        updateFields.forEach((rowField) => {
          let field = this.dataValidationValues.find(
            (row) => row.fieldName == rowField
          );
          if (field) {
            field.formValue = this.getModel(rowField);
            field.status =
              field.status === "Verified" ? "Not Verified" : field.status;
          }
        });
        const { error } = await custom.updateArmgroupMLValidation(
          this.dataValidationValues
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
      }
    },

    async confirmLoanInfo() {
      this.loanInfoVerify = true;
      let loanInfo = [];
      let field = this.dataValidationValues.find(
        (row) => row.fieldName == "Loan Info"
      );
      if (field) {
        field.formValue = "Checked";
        field.status =
          field.status === "Not Matched" || field.status === "Not Verified"
            ? "Verified"
            : field.status;
        loanInfo.push(field);
      } else {
        loanInfo = [
          {
            processId: this.selectedProcess.processId,
            fieldName: "Loan Info",
            formValue: "Checked",
            status: "Verified",
          },
        ];
      }
      if (loanInfo[0].id) {
        const { error } = await custom.updateArmgroupMLValidation(loanInfo);
        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await custom.insertArmgroupMLValidation(loanInfo);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }
      this.getDataValidation();
    },

    async unConfirmLoanInfo() {
      this.loanInfoVerify = false;
      let loanInfo = [];
      let field = this.dataValidationValues.find(
        (row) => row.fieldName == "Loan Info"
      );
      if (field) {
        field.formValue = "";
        field.status = "Not Verified";
        loanInfo.push(field);
      }
      const { error } = await custom.updateArmgroupMLValidation(loanInfo);
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async confirmClaimInfo() {
      this.claimInfoVerify = true;
      let claimInfo = [];
      let field = this.dataValidationValues.find(
        (row) => row.fieldName == "Claim Info"
      );
      if (field) {
        field.formValue = "Checked";
        field.status =
          field.status === "Not Matched" || field.status === "Not Verified"
            ? "Verified"
            : field.status;
        claimInfo.push(field);
      } else {
        claimInfo = [
          {
            processId: this.selectedProcess.processId,
            fieldName: "Claim Info",
            formValue: "Checked",
            status: "Verified",
          },
        ];
      }
      if (claimInfo[0].id) {
        const { error } = await custom.updateArmgroupMLValidation(claimInfo);
        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await custom.insertArmgroupMLValidation(claimInfo);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }
      this.getDataValidation();
    },

    async unConfirmClaimInfo() {
      this.claimInfoVerify = false;
      let claimInfo = [];
      let field = this.dataValidationValues.find(
        (row) => row.fieldName == "Claim Info"
      );
      if (field) {
        field.formValue = "";
        field.status = "Not Verified";
        claimInfo.push(field);
      }
      const { error } = await custom.updateArmgroupMLValidation(claimInfo);
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    export_Data() {
      if (this.paymentTableValues.length) {
        let tableData = [];
        tableData = this.paymentTableValues.map((item) => ({
          "Payment Number": item.paymentNumber,
          "Base Amount": item.baseAmount,
          "Claim Amount": item.claimAmount,
          "Cumulative Total": item.cumulativeTotal,
          Approval: item.approval,
          "Approved Date": item.approvedDate,
          "Payment Date": item.paymentDate,
          "Payment Frequency": item.benefitType,
        }));
        exportData(tableData);
      }
    },

    paymentApprove() {
      if (this.numberOfApprovePayment > 0) {
        if (this.numberOfApprovePayment > this.paymentTableValues.length) {
          this.$alert.warning(
            "Approved payment should be less than " +
              this.paymentTableValues.length
          );
        } else {
          if (this.isPaymentFound) {
            let approvedPayments = this.paymentTableValues.filter(
              (row) => row.approval !== "Approved"
            );
            if (approvedPayments.length) {
              approvedPayments.forEach((row, index) => {
                if (index < this.numberOfApprovePayment) {
                  row.approval = "Approved";
                  let days = 0;
                  // switch (this.benefitType) {
                  //   case "Monthly":
                  //     days = 28;
                  //     break;
                  //   case "BiWeekly":
                  //   case "SemiMonthly":
                  //     days = 14;
                  //     break;
                  //   case "Weekly":
                  //     days = 7;
                  //     break;
                  // }
                  row.approvedDate = this.$day.addDate(
                    this.$day.newDate(),
                    index * days
                  );
                  row.paymentDate = this.$day.addDate(
                    this.$day.newDate(),
                    index * days + 1
                  );
                  row.approvedBy = this.userInitials;
                } else {
                  row.approval = "";
                  row.approvedDate = "";
                  row.paymentDate = "";
                  row.approvedBy = "";
                }
              });
            }
          } else {
            this.paymentTableValues.forEach((row, index) => {
              if (index < this.numberOfApprovePayment) {
                row.approval = "Approved";
                let days = 0;
                // switch (this.benefitType) {
                //   case "Monthly":
                //     days = 28;
                //     break;
                //   case "BiWeekly":
                //   case "SemiMonthly":
                //     days = 14;
                //     break;
                //   case "Weekly":
                //     days = 7;
                //     break;
                // }
                row.approvedDate = this.$day.addDate(
                  this.$day.newDate(),
                  index * days
                );
                row.paymentDate = this.$day.addDate(
                  this.$day.newDate(),
                  index * days + 1
                );
                row.approvedBy = this.userInitials;
              } else {
                row.approval = "";
                row.approvedDate = "";
                row.paymentDate = "";
                row.approvedBy = "";
              }
            });
          }
        }
      } else {
        this.$alert.warning("Specify the valid number");
      }
    },

    clearPaymentApprove() {
      this.numberOfApprovePayment = 0;
      this.paymentTableValues.forEach((row) => {
        row.approval = "";
        row.approvedDate = "";
        row.paymentDate = "";
        row.approvedBy = "";
      });
    },

    checkApprovalEdit(row) {
      if (row.approvedDate) {
        if (this.$day.newDate() === row.approvedDate) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },

    updateApprovalDate(rowIndex) {
      if (this.paymentTableValues[rowIndex].approval === "Approved") {
        this.paymentTableValues[rowIndex].approvedDate = this.$day.newDate();
        let dt = new Date();
        dt.setDate(dt.getDate() + 1);
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = dt.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        this.paymentTableValues[
          rowIndex
        ].paymentDate = `${dt.getFullYear()}-${month}-${day}`;

        this.paymentTableValues[rowIndex].approvedBy = this.userInitials;
      } else {
        this.paymentTableValues[rowIndex].approvedDate = "";
        this.paymentTableValues[rowIndex].paymentDate = "";
        this.paymentTableValues[rowIndex].approvedBy = "";
      }
    },

    addLumpsumRow() {
      if (
        !this.paymentTableValues[this.paymentTableValues.length - 1].lumpsumRow
      ) {
        let cumulativeTotal = 0;
        if (
          Number(
            this.paymentTableValues[this.paymentTableValues.length - 1]
              .cumulativeTotal
          ) === 4000.0
        ) {
          cumulativeTotal = (4000).toFixed(2);
        }
        const model = {
          paymentId: 0,
          paymentNumber: this.paymentTableValues.length + 1,
          baseAmount: "",
          claimAmount: 0,
          cumulativeTotal: cumulativeTotal,
          approval: "",
          approvedDate: "",
          paymentDate: "",
          approvedBy: "",
          notes: "",
          goodWill: false,
          lppPlus: false,
          preApprovedPmt: false,
          psgCovered: false,
          benefitType: this.benefitType,
          lumpsumRow: true,
          delete: true,
        };

        this.paymentTableValues.push(model);
      }
    },

    addPaymentRow() {
      let total = 0;
      if (this.paymentTableValues.length) {
        total = Number(
          this.paymentTableValues[this.paymentTableValues.length - 1]
            .cumulativeTotal
        );
      }

      if (total === 4000 && !this.hasCIClaimsType) {
        this.$alert.warning("Already maximum amount 4000 reached");
      } else if (total === 15000 && this.hasCIClaimsType) {
        this.$alert.warning("Already maximum amount 15000 reached");
      } else {
        const model = {
          paymentId: 0,
          paymentNumber: this.paymentTableValues.length + 1,
          baseAmount: "",
          claimAmount: 0,
          cumulativeTotal: 0,
          approval: "",
          approvedDate: "",
          paymentDate: "",
          approvedBy: "",
          notes: "",
          goodWill: false,
          lppPlus: false,
          preApprovedPmt: false,
          psgCovered: false,
          benefitType: this.benefitType,
          delete: true,
        };

        this.paymentTableValues.push(model);
      }
    },

    removePaymentRow(rowIdx) {
      this.paymentTableValues.splice(rowIdx, 1);
    },

    calcOtherColumns(rowIdx) {
      let total = 0;
      let baseAmount = this.paymentTableValues[rowIdx].baseAmount;
      if (this.hasOtherClaimsType) {
        if (this.paymentTableValues[rowIdx].lumpsumRow) {
          if (baseAmount > 2000) {
            this.paymentTableValues[rowIdx].baseAmount = 0;
            this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
            this.$alert.warning("Lumpsum Amount shouldn't exceed $2000");
            return;
          }
          this.paymentTableValues[rowIdx].claimAmount = (0).toFixed(2);
          total = Number(
            this.paymentTableValues[this.paymentTableValues.length - 2]
              .cumulativeTotal
          );
          if (
            total === 4000 &&
            this.paymentTableValues[rowIdx].baseAmount > 0
          ) {
            this.paymentTableValues[rowIdx].baseAmount = 0;
            this.paymentTableValues[rowIdx].cumulativeTotal = (4000).toFixed(2);
            this.$alert.warning("Cumulative total shouldn't exceed $4000");
            return;
          }
          total += baseAmount;
          if (total > 4000) {
            this.paymentTableValues[rowIdx].baseAmount = 0;
            this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
            this.$alert.warning("Cumulative total shouldn't exceed $4000");
            return;
          }
        } else {
          let claimAmount = baseAmount + baseAmount * 0.2;

          this.paymentTableValues[rowIdx].claimAmount = claimAmount.toFixed(2);
          total =
            claimAmount +
            Number(this.paymentTableValues[rowIdx - 1].cumulativeTotal);
          // this.paymentTableValues.forEach((row) => {
          //   total += Number(row.claimAmount);
          // });
          if (this.STLOCTypeFormula) {
            if (total > 4000) {
              this.paymentTableValues[rowIdx].baseAmount = 0;
              this.paymentTableValues[rowIdx].claimAmount = 0;
              this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
              total = 0;
              this.$alert.warning("Cumulative total shouldn't exceed $4000");
              return;
            }
          }
          // total = Number(
          //   this.paymentTableValues[this.paymentTableValues.length - 2]
          //     .cumulativeTotal
          // );
        }
        if (total > 0 && baseAmount) {
          this.paymentTableValues[rowIdx].cumulativeTotal = total.toFixed(2);
        }
      } else if (this.hasLifeType) {
        if (this.ENHTypeFormula) {
          if (baseAmount > 50000) {
            this.paymentTableValues[rowIdx].baseAmount = 0;
            this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
            this.$alert.warning("Base Amount shouldn't exceed $50000");
            return;
          }
        } else {
          if (baseAmount > 15000) {
            this.paymentTableValues[rowIdx].baseAmount = 0;
            this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
            this.$alert.warning("Base Amount shouldn't exceed $15000");
            return;
          }
        }

        this.paymentTableValues[rowIdx].cumulativeTotal =
          this.paymentTableValues[rowIdx].baseAmount.toFixed(2);
      } else if (this.hasCIClaimsType) {
        let lastValue = 0;
        if (rowIdx) {
          lastValue =
            Number(this.paymentTableValues[rowIdx - 1].cumulativeTotal) +
            baseAmount;
        }

        if (baseAmount <= 15000 && rowIdx) {
          this.paymentTableValues[rowIdx].cumulativeTotal =
            this.paymentTableValues[rowIdx].baseAmount.toFixed(2);
          return;
        } else if (baseAmount > 15000 && !rowIdx) {
          this.paymentTableValues[rowIdx].baseAmount = 0;
          this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
          this.$alert.warning("Base Amount shouldn't exceed $15000");
          return;
        } else if (lastValue > 15000) {
          this.paymentTableValues[rowIdx].baseAmount = 0;
          this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
          this.$alert.warning("Base Amount shouldn't exceed $15000");
          return;
        }

        this.paymentTableValues[rowIdx].cumulativeTotal =
          this.paymentTableValues[rowIdx].baseAmount.toFixed(2);
      }
    },

    calcOtherColumns1(rowIdx) {
      let total = 0;
      let baseAmount = this.paymentTableValues[rowIdx].baseAmount;
      if (this.paymentTableValues[rowIdx].lumpsumRow) {
        if (baseAmount > 2000) {
          this.paymentTableValues[rowIdx].baseAmount = 0;
          this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
          this.$alert.warning("Lumpsum Amount shouldn't exceed $2000");
          return;
        }
        this.paymentTableValues[rowIdx].claimAmount = (0).toFixed(2);
        total = Number(
          this.paymentTableValues[this.paymentTableValues.length - 2]
            .cumulativeTotal
        );
        if (total === 4000 && this.paymentTableValues[rowIdx].baseAmount > 0) {
          this.paymentTableValues[rowIdx].baseAmount = 0;
          this.paymentTableValues[rowIdx].cumulativeTotal = (4000).toFixed(2);
          this.$alert.warning("Cumulative total shouldn't exceed $4000");
          return;
        }
        total += baseAmount;
        if (total > 4000) {
          this.paymentTableValues[rowIdx].baseAmount = 0;
          this.paymentTableValues[rowIdx].cumulativeTotal = (0).toFixed(2);
          this.$alert.warning("Cumulative total shouldn't exceed $4000");
          return;
        }
      } else {
        let claimAmount = baseAmount + baseAmount * 0.2;
        this.paymentTableValues[rowIdx].claimAmount = claimAmount.toFixed(2);
        this.paymentTableValues.forEach((row) => {
          total += Number(row.claimAmount);
        });
        // total = Number(
        //   this.paymentTableValues[this.paymentTableValues.length - 2]
        //     .cumulativeTotal
        // );
      }
      this.paymentTableValues[rowIdx].cumulativeTotal = total.toFixed(2);
    },

    claimStatusSearchRow(search) {
      if (search) {
        this.claimStatusValues = this.claimStatusValuesData.filter((row) => {
          for (let cell in row) {
            let index = this.claimStatusColumns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.claimStatusValues = this.claimStatusValuesData;
      }
    },

    loadAllClaimStatus() {
      this.claimStatusItemsPerPage = this.claimStatusTotalItems;
      if (this.claimStatusCurrentPage === 1) {
        this.getClaimStatus();
      } else {
        this.claimStatusCurrentPage = 1;
      }
    },

    dailyDataStatusSearchRow(search) {
      if (search) {
        this.dailyDataStatusValues = this.dailyDataStatusValuesData.filter(
          (row) => {
            for (let cell in row) {
              let index = this.dailyDataStatusColumns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }
        );
      } else {
        this.dailyDataStatusValues = this.dailyDataStatusValuesData;
      }
    },

    loadAllDailyDataStatus() {
      this.dailyDataStatusItemsPerPage = this.dailyDataStatusTotalItems;
      if (this.dailyDataStatusCurrentPage === 1) {
        this.getDailyDataStatus();
      } else {
        this.dailyDataStatusCurrentPage = 1;
      }
    },

    paymentHistorySearchRow(search) {
      if (search) {
        this.paymentHistoryValues = this.paymentHistoryValuesData.filter(
          (row) => {
            for (let cell in row) {
              let index = this.paymentHistoryColumns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }
        );
      } else {
        this.paymentHistoryValues = this.payymentHistoryValuesData;
      }
    },

    loadAllPaymentHistory() {
      this.paymentHistoryItemsPerPage = this.paymentHistoryTotalItems;
      if (this.paymentHistoryCurrentPage === 1) {
        this.getPaymentHistory();
      } else {
        this.payemntHistoryCurrentPage = 1;
      }
    },

    addClaimStatus() {
      this.claimStatusRow = {};
      this.claimStatus = "";
      this.csDate = "";
      this.csNote = 0;
      this.claimLetterSent = 0;
      this.mailingType = "";
      this.lenderLetter = "";
      this.internalOnly = false;
      this.internalNotes = "";
      this.claimStatusModal = true;
    },

    editClaimStatus(item) {
      this.claimStatusRow = this.claimStatusValues.find(
        (row) => row.ClaimStatusID === item.ClaimStatusID
      );
      if (this.claimStatusRow) {
        this.claimStatus = this.claimStatusRow.ClaimStatus;
        this.csNote = this.claimStatusRow.CSNote;
        this.claimLetterSent = this.claimStatusRow.ClaimLetterSent;
        this.mailingType = this.claimStatusRow.MailingType;
        this.lenderLetter = this.claimStatusRow.lenderLetter;
        this.internalOnly = Boolean(this.claimStatusRow.InternalOnly);
        this.internalNotes = this.claimStatusRow.InternalNotes;
      }
      this.claimStatusModal = true;
    },

    async getClaimStatusMaster(formId) {
      this.claimStatusList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntries(formId, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [],
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      this.claimStatusList = data[0].value.map((entry) => ({
        id: entry.itemid,
        label: entry["dKY2zhKPS4-hQy1I6yVSV"],
        value: entry["dKY2zhKPS4-hQy1I6yVSV"],
      }));
    },

    async paymentCalculation() {
      this.numberOfApprovePayment = 0;
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      if (this.isPaymentFound) {
        this.clearPayment = true;
      }
      this.paymentTableValues = [];
      let total = 0;
      let benefit = 0;
      if (this.benefitType) {
        switch (this.benefitType) {
          case "Monthly":
            benefit = 6;
            break;
          case "BiWeekly":
          case "SemiMonthly":
            benefit = 12;
            break;
          case "Weekly":
            benefit = 24;
            break;
        }
      }
      for (let i = 1; i <= benefit; i++) {
        // let baseAmount = this.totalPayment * 1.2;
        // let claimAmount = baseAmount + baseAmount * 0.2;
        if (this.hasLMUFLClaimsType && i > 3) {
          return;
        }
        let baseAmount = this.totalPayment;
        let claimAmount = 0;
        let maxAmount = 0;
        if (this.STLOCTypeFormula) {
          if (this.hasOtherClaimsType) {
            maxAmount = 4000;
          } else if (this.hasLMUFLClaimsType) {
            maxAmount = 2000;
          }
          claimAmount = baseAmount + baseAmount * 0.2;
        } else if (this.ENHTypeFormula) {
          if (this.hasOtherClaimsType) {
            maxAmount = 6000;
          } else if (this.hasLMUFLClaimsType) {
            maxAmount = 2000;
          }
        } else if (this.mobileFinanceFormula) {
          if (this.hasOtherClaimsType) {
            maxAmount = 7500;
          } else if (this.hasLMUFLClaimsType) {
            maxAmount = 2000;
          }
        } else if (this.moneyMartFormula) {
          if (this.hasLMUFLClaimsType) {
            maxAmount = 2000;
          }
        }
        // if (total + this.lumpsumAmount > 4000) {
        //   return;
        // }

        // console.log(total, total > maxAmount, total < maxAmount);
        if (!this.moneyMartFormula) {
          if (total + claimAmount > maxAmount) {
            this.paymentTableValues.push({
              paymentId: 0,
              paymentNumber: i,
              baseAmount: (maxAmount - total).toFixed(2),
              claimAmount: (0).toFixed(2),
              cumulativeTotal: maxAmount.toFixed(2),
              approval: "",
              approvedDate: "",
              paymentDate: "",
              approvedBy: "",
              notes: "",
              goodWill: false,
              lppPlus: false,
              preApprovedPmt: false,
              psgCovered: false,
              benefitType: this.benefitType,
            });

            return;
          }
        }

        //  total += claimAmount;
        if (this.STLOCTypeFormula) {
          total += claimAmount;
        } else if (this.ENHTypeFormula) {
          total += baseAmount;
        } else if (this.mobileFinanceFormula) {
          total += baseAmount;
        } else if (this.moneyMartFormula) {
          let doc = this.getModel("Principal Balance At Doc");

          let benefitPer = 0;
          let enhCalculation = 0;
          if (this.hasOtherClaimsType && doc) {
            if (
              this.benefitType === "BiWeekly" ||
              this.benefitType === "SemiMonthly"
            ) {
              let calculation = (doc * 50) / 100;
              benefitPer = calculation.toFixed(2);
            } else if (this.benefitType === "Weekly") {
              let calculation = (doc * 25) / 100;
              benefitPer = calculation.toFixed(2);
            } else if (this.benefitType === "Monthly") {
              benefitPer = doc;
            }
          }
          enhCalculation = benefitPer / 24;
          claimAmount = enhCalculation;
          total += enhCalculation + baseAmount;
          // console.log(enhCalculation);
        }
        this.paymentTableValues.push({
          paymentId: 0,
          paymentNumber: i,
          baseAmount: baseAmount.toFixed(2),
          claimAmount: claimAmount.toFixed(2),
          cumulativeTotal: total.toFixed(2),
          approval: "",
          approvedDate: "",
          paymentDate: "",
          approvedBy: "",
          notes: "",
          goodWill: false,
          lppPlus: false,
          preApprovedPmt: false,
          psgCovered: false,
          benefitType: this.benefitType,
        });

        if (total === maxAmount) {
          return;
        }
      }
      // this.paymentTableValues.push({
      //   paymentNumber: benefit + 1,
      //   baseAmount: "",
      //   claimAmount: "",
      //   cumulativeTotal: "",
      //   approval: "",
      //   approvedDate: "",
      //   paymentDate: "",
      //   benefitType: this.benefitType,
      //   edit: true,
      // });
    },

    async paymentCalculation1() {
      this.numberOfApprovePayment = 0;
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      if (this.isPaymentFound) {
        this.clearPayment = true;
      }
      this.paymentTableValues = [];
      let total = 0;
      let benefit = 0;
      if (this.benefitType) {
        switch (this.benefitType) {
          case "Monthly":
            benefit = 6;
            break;
          case "BiWeekly":
          case "SemiMonthly":
            benefit = 12;
            break;
          case "Weekly":
            benefit = 24;
            break;
        }
      }
      for (let i = 1; i <= benefit; i++) {
        // let baseAmount = this.totalPayment * 1.2;
        // let claimAmount = baseAmount + baseAmount * 0.2;
        let baseAmount = this.totalPayment;
        let claimAmount = baseAmount + baseAmount * 0.2;
        // if (total + this.lumpsumAmount > 4000) {
        //   return;
        // }
        if (total + claimAmount > 4000) {
          this.paymentTableValues.push({
            paymentId: 0,
            paymentNumber: i,
            baseAmount: (4000 - total).toFixed(2),
            claimAmount: (0).toFixed(2),
            cumulativeTotal: (4000).toFixed(2),
            approval: "",
            approvedDate: "",
            paymentDate: "",
            approvedBy: "",
            notes: "",
            goodWill: false,
            lppPlus: false,
            preApprovedPmt: false,
            psgCovered: false,
            benefitType: this.benefitType,
          });
          return;
        }
        total += claimAmount;
        this.paymentTableValues.push({
          paymentId: 0,
          paymentNumber: i,
          baseAmount: baseAmount.toFixed(2),
          claimAmount: claimAmount.toFixed(2),
          cumulativeTotal: total.toFixed(2),
          approval: "",
          approvedDate: "",
          paymentDate: "",
          approvedBy: "",
          notes: "",
          goodWill: false,
          lppPlus: false,
          preApprovedPmt: false,
          psgCovered: false,
          benefitType: this.benefitType,
        });
      }
      // this.paymentTableValues.push({
      //   paymentNumber: benefit + 1,
      //   baseAmount: "",
      //   claimAmount: "",
      //   cumulativeTotal: "",
      //   approval: "",
      //   approvedDate: "",
      //   paymentDate: "",
      //   benefitType: this.benefitType,
      //   edit: true,
      // });
    },

    async getPaymentTable1() {
      this.isPaymentFound = false;
      const { error, payload } = await custom.getPaymentTable({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.selectedProcess.processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.paymentTableValues = payload;
        this.isPaymentFound = true;
      }
    },

    async getPaymentTable() {
      this.isPaymentFound = false;
      this.paymentTableValues = [];
      let claimNumberField = this.formFields.find(
        (row) => row.label === "Claim No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let condition = {};

      if (!this.isPaymentFound) {
        if (this.hasLifeType || this.hasCIClaimsType) {
          this.getLifeTypePaymentTable();
        }
      }
      condition["CPClaimNumber"] = this.formModel[claimNumberField.value];
      let input = {
        hublinkId: 8,
        payloadMapping: JSON.stringify(condition),
        lenderName: this.formModel[lenderNameField.value],
        itemsPerPage: 50,
        currentPage: 1,
      };
      const { payload, error } = await custom.getDataSync(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          if (data[0].value.length) {
            //this.paymentTableValues = data[0].value;
            this.paymentTableValues = [];
            data[0].value.forEach((row) => {
              this.paymentTableValues.push({
                paymentId: row.ClaimPaymentID,
                paymentNumber: row.ClaimPaymentNumber,
                baseAmount: Number(row.ClaimPaymentAmount).toFixed(2),
                claimAmount: Number(row.PrincipalPmt).toFixed(2),
                cumulativeTotal: Number(row.CumulativeTotal).toFixed(2),
                approval: row.Status,
                approvedDate: this.$day.parseDate(row.StatusDate),
                paymentDate: this.$day.parseDate(row.CPaymentDate),
                approvedBy: row.ApprovedBy,
                notes: row.CPNotes,
                goodWill: row.Goodwill === "True" ? true : false,
                lppPlus: row.LPPPlus === "True" ? true : false,
                preApprovedPmt: row.PreApprovedPmt === "True" ? true : false,
                psgCovered: row.PSGCovered === "True" ? true : false,
              });
            });
            this.isPaymentFound = true;
          }
        }
      }
    },

    async updatePaymentTable1() {
      this.paymentTableValues.forEach((row) => {
        row.baseAmount = Number(row.baseAmount).toFixed(2);
      });
      let input = {
        workflowId: this.selectedProcess.id,
        processId: this.selectedProcess.processId,
        paymentTableList: this.paymentTableValues,
      };
      if (this.isPaymentFound) {
        const { error } = await custom.updatePaymentTable(
          this.selectedProcess.processId,
          input
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success("Payment entry updated");
      } else {
        const { error } = await custom.savePaymentTable(input);

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success("Payment entry added");
      }
      this.getPaymentTable();
    },

    async updatePaymentTable() {
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let claimNumberField = this.formFields.find(
        (row) => row.label === "Claim No."
      );
      this.paymentTableValues.forEach((row) => {
        row.baseAmount = Number(row.baseAmount).toFixed(2);
      });
      let paymentRow = [];
      this.paymentTableValues.forEach((row) => {
        let dtObj = {};
        if (row.paymentId) {
          dtObj = {
            modifiedBy: this.userNames,
            modifiedDt: this.$day.newDateTime(),
          };
        } else {
          dtObj = {
            createdBy: this.userNames,
            createdDt: this.$day.newDateTime(),
          };
        }
        paymentRow.push({
          claimPaymentID: row.paymentId,
          cPClaimNumber: this.formModel[claimNumberField.value],
          paymentNumber: row.paymentNumber,
          paymentBaseAmount: row.baseAmount,
          claimAmount: row.claimAmount,
          cumulativeTotal: row.cumulativeTotal,
          approval: row.approval,
          paymentDate: row.paymentDate,
          approvedBy: 0, //row.approvedBy,
          cpNotes: row.notes,
          goodwill: row.goodWill,
          lppplus: row.lppPlus,
          preApprovedPMT: row.preApprovedPmt,
          psgCovered: row.psgCovered,
          approvalDate: row.approvedDate,
          proRated: false,
          ...dtObj,
        });
      });
      let input = {
        lenderName: this.formModel[lenderNameField.value],
        claimNumber: this.clearPayment
          ? this.formModel[claimNumberField.value]
          : "",
        paymentList: paymentRow,
      };
      const { error } = await custom.savePaymentTable(input);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("Payment entry updated");
      this.numberOfApprovePayment = 0;
      this.getPaymentTable();
    },

    async getContractData(value) {
      let loanNumberField = this.formFields.find(
        (row) => row.label === "Loan No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let condition = {};
      condition["StoreLoanNumber"] = this.formModel[loanNumberField.value];
      if (value === "Borrower") {
        let input = {
          hublinkId: 4,
          payloadMapping: JSON.stringify(condition),
          lenderName: this.formModel[lenderNameField.value],
          itemsPerPage: 500,
          currentPage: 1,
        };
        const { payload, error } = await custom.getDataSync(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          if (error === "Connection Interrupted") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          return;
        }

        const { data, meta } = payload;

        if (meta.totalItems) {
          if (data.length) {
            if (data[0].value.length) {
              data[0].value.forEach((item) => {
                this.updateModel(item.FirstName, "First Name");
                this.updateModel(item.LastName, "Last Name");
                this.updateModel(item.DOB, "DOB");
                this.updateModel(item.Address1, "Address");
                this.updateModel(item.City, "City");
                this.updateModel(item.Province, "Province");
                this.updateModel(item.PostalCode, "Postal Code");
                this.updateModel(item.Phone, "Phone No.");
              });
            }
          }
        }
      } else if (value === "Co-Borrower") {
        let input = {
          hublinkId: 3,
          payloadMapping: JSON.stringify(condition),
          lenderName: this.formModel[lenderNameField.value],
          itemsPerPage: 500,
          currentPage: 1,
        };
        const { payload, error } = await custom.getDataSync(input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          if (error === "Connection Interrupted") {
            this.$alert.warning(error);
          } else {
            this.$alert.error(error);
          }
          return;
        }

        const { data, meta } = payload;

        if (meta.totalItems) {
          if (data.length) {
            if (data[0].value.length) {
              data[0].value.forEach((item) => {
                this.updateModel(item.CoFirstName, "First Name");
                this.updateModel(item.CoLastName, "Last Name");
                this.updateModel(item.CoDOB, "DOB");
                this.updateModel(item.CoAddress1, "Address");
                this.updateModel(item.CoCity, "City");
                this.updateModel(item.CoProvince, "Province");
                this.updateModel(item.CoPostalCode, "Postal Code");
                this.updateModel(item.CoPhone, "Phone No.");
              });
            }
          }
        }
      }
    },

    async getClaimStatus() {
      this.claimStatusValues = [];
      this.claimStatusValuesData = [];
      let claimNumberField = this.formFields.find(
        (row) => row.label === "Claim No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let condition = {};
      condition["CSClaimNumber"] = this.formModel[claimNumberField.value];
      let input = {
        hublinkId: 7,
        payloadMapping: JSON.stringify(condition),
        lenderName: this.formModel[lenderNameField.value],
        itemsPerPage: this.claimStatusItemsPerPage,
        currentPage: this.claimStatusCurrentPage,
      };
      const { payload, error } = await custom.getDataSync(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data, meta } = payload;
      if (meta.totalItems) {
        this.claimStatusTotalItems = meta.totalItems;
        if (data.length) {
          if (data[0].value.length) {
            this.claimStatusValues = data[0].value;
            this.claimStatusValuesData = data[0].value;
          }
        }
      }
    },

    async saveClaimStatus() {
      const isValid = await this.$refs.claimStatusModal.validate();
      if (!isValid) {
        return;
      }
      let claimNumberField = this.formFields.find(
        (row) => row.label === "Claim No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let fields = {};
      if (Object.keys(this.claimStatusRow).length) {
        fields = {
          CSClaimNumber: this.claimStatusRow.CSClaimNumber,
          ClaimStatus: this.claimStatus,
          CSDate: this.claimStatusRow.CSDate,
          //CSNote: this.csNote,
          ClaimLetterSent: 0, //this.claimLetterSent,
          AuthorizedBy: 0, //this.claimStatusRow.AuthorizedBy,
          MailingType: this.mailingType,
          LenderLetter: 0, //this.lenderLetter,
          CreatedBy: this.claimStatusRow.CreatedBy,
          CreatedDt: this.claimStatusRow.CreatedDt,
          ModifiedBy: this.userNames,
          ModifiedDt: this.$day.newDateTime(),
          InternalOnly: this.internalOnly,
          InternalNotes: this.internalNotes,
          TransactionId: "",
          InhouseOnly: 0,
        };
      } else {
        fields = {
          CSClaimNumber: this.formModel[claimNumberField.value],
          ClaimStatus: this.claimStatus,
          CSDate: this.$day.newDate(),
          // CSNote: this.csNote,
          ClaimLetterSent: 0, //this.claimLetterSent,
          AuthorizedBy: 0, //this.userInitials,
          MailingType: this.mailingType,
          LenderLetter: 0, //this.lenderLetter,
          CreatedBy: this.userNames,
          CreatedDt: this.$day.newDateTime(),
          InternalOnly: this.internalOnly,
          InternalNotes: this.internalNotes,
          TransactionId: "",
          InhouseOnly: 0,
        };
      }

      let input = {
        lenderName: this.formModel[lenderNameField.value],
        claimStatusID: Object.keys(this.claimStatusRow).length
          ? this.claimStatusRow.ClaimStatusID
          : 0,
        fields: JSON.stringify(fields),
      };
      const { error } = await custom.saveClaimStatus(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (Object.keys(this.claimStatusRow).length) {
        this.$alert.success("Claim status entry updated");
      } else {
        this.$alert.success("Claim status entry added");
      }
      this.claimStatusRow = {};
      this.claimStatusModal = false;
      this.getClaimStatus();
    },

    async getDailyDataStatus() {
      this.dailyDataStatusValues = [];
      this.dailyDataStatusValuesData = [];
      let loanNumberField = this.formFields.find(
        (row) => row.label === "Loan No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let condition = {};
      condition["loankey"] = this.formModel[loanNumberField.value];
      let input = {
        hublinkId: 6,
        payloadMapping: JSON.stringify(condition),
        lenderName: this.formModel[lenderNameField.value],
        itemsPerPage: this.dailyDataStatusItemsPerPage,
        currentPage: this.dailyDataStatusCurrentPage,
      };
      const { payload, error } = await custom.getDataSync(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data, meta } = payload;
      if (meta.totalItems) {
        this.dailyDataStatusTotalItems = meta.totalItems;
        if (data.length) {
          if (data[0].value.length) {
            this.dailyDataStatusValues = data[0].value;
            this.dailyDataStatusValuesData = data[0].value;
          }
        }
      }
    },

    async getPaymentHistory() {
      this.paymentHistoryValues = [];
      this.paymentHistoryValuesData = [];
      let loanNumberField = this.formFields.find(
        (row) => row.label === "Loan No."
      );
      let lenderNameField = this.formFields.find(
        (row) => row.label === "Lender Name"
      );
      let condition = {};
      condition["StoreLoanNumber"] = this.formModel[loanNumberField.value];
      let input = {
        hublinkId: 5,
        payloadMapping: JSON.stringify(condition),
        lenderName: this.formModel[lenderNameField.value],
        itemsPerPage: this.paymentHistoryItemsPerPage,
        currentPage: this.paymentHistoryCurrentPage,
      };
      this.$store.commit("showLoadingBarSave");
      const { payload, error } = await custom.getDataSync(input);
      this.$store.commit("hideLoadingBarSave");
      if (error) {
        this.$alert.error(error);
        return;
      }
      const { data, meta } = payload;
      if (meta.totalItems) {
        this.paymentHistoryTotalItems = meta.totalItems;
        if (data.length) {
          if (data[0].value.length) {
            this.paymentHistoryValues = data[0].value;
            this.paymentHistoryValuesData = data[0].value;
          }
        }
      }
    },

    getLifeTypePaymentTable() {
      this.paymentTableValues = [];

      this.paymentTableValues = [
        {
          approval: "",
          approvedBy: "",
          approvedDate: "",
          baseAmount: "",
          claimAmount: 0,
          cumulativeTotal: "0",
          goodWill: false,
          lppPlus: false,
          notes: "",
          paymentDate: "",
          paymentId: 0,
          paymentNumber: "1",
          preApprovedPmt: false,
          psgCovered: false,
          delete: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-process-form-view {
  margin: auto;
  padding: 8px;
  background-color: var(--body-bg-color);
  font-size: 11px;

  .content {
    padding: 6px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: var(--component-bg-color);

    .content-wrapper {
      border-top: 1px solid var(--divider-color);
      padding-top: 8px;
    }

    .searchBtn {
      margin-top: 33px;
    }

    .full-border {
      border: 5px solid var(--divider-color);
      border-radius: 10px;
    }

    .header {
      font-weight: bold;
      font-size: 13px;
    }
  }

  table {
    border-spacing: 0px;
    &:not(.single-border) {
      border: 5px solid var(--divider-color);
    }
    &.single-border {
      border: 1px solid var(--divider-color);
    }
    border-radius: 10px;
    white-space: nowrap;
    width: 100%;

    &.table-content th {
      border-bottom: 1px solid var(--divider-color);
    }

    &.tab-table.empty {
      min-height: 200px;
    }

    tr {
      th {
        color: var(--secondary);
        text-align: left;
        &.actions {
          width: 36px;
        }
      }
    }

    th,
    td {
      padding: 3px;
    }

    th:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color);
    }

    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }
  }

  .loan-info {
    td {
      width: 25%;
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  .tab-table #action-btn.small {
    width: 24px !important;
    height: 24px !important;
  }

  .payment-action {
    margin-top: 38px;
    &#field-label {
      margin-top: 42px;
    }
  }

  .claim-info {
    th,
    td {
      width: 25%;
    }
  }

  #pagination.custom-form-table {
    padding-top: 5px !important;
    border-top: none;
  }
}
</style>

<style lang="scss">
.custom-controls {
  #field-wrapper {
    height: 30px !important;
  }

  .options-wrapper {
    padding-top: 6px !important;
    .row {
      margin-top: -8px;
      margin-left: -8px;
      .option {
        padding-top: 8px;
        padding-left: 8px;
      }
    }
  }

  &.no-border {
    .options-wrapper {
      border: none !important;
    }
  }

  &#multiple-choice-field .option {
    overflow: hidden !important;
  }

  &#checkbox-field .icon {
    margin: auto !important;
  }
}

#button.custom-button {
  min-height: 28px;
  max-height: 28px;
}

#paymentTable .controls {
  #field-label {
    font-size: 11px !important;
  }

  #field-wrapper {
    width: 120px;
  }
}

#modal .claim-status {
  .field {
    margin: 16px 0px 16px 16px;
  }
}
</style>
