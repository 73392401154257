<template>
  <div id="global-search" ref="search">
    <BaseIcon name="eva-search" />

    <div class="label">
      <!-- <div>Search...</div> -->
      <input
        ref="searchQuery"
        v-model="search"
        type="text"
        class="q-px-xs"
        placeholder="Search..."
      />
      <q-space />
      <!-- <div class="font-bold">Ctrl K</div> -->
      <BaseActionButton
        v-if="false"
        v-tooltip.top="'Show search options'"
        is-flat
        no-border
        icon="eva-options-2-outline"
        @click="showSearchOptions = !showSearchOptions"
      >
      </BaseActionButton>
      <!-- @click="$emit('show-files')" -->
    </div>
    <q-menu
      v-model="showSearchOptions"
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom end"
      self="top right"
      no-parent-event
      fit
      class="shadow-3"
    >
      <!-- <div class="row justify-end">
          <BaseCloseButton />
        </div> -->
      <BaseScrollbar height="calc(80vh - 273px)" width="600px" class="q-pb-sm">
        <div id="search-menu">
          <table>
            <tbody>
              <tr>
                <td class="label">Type</td>

                <td class="value">
                  <SelectField v-model="type" :options="typeList" />
                </td>
                <!-- <td>
                  <div class="row justify-end">
                    <BaseCloseButton />
                  </div>
                </td> -->
              </tr>

              <template v-if="type">
                <tr v-if="type === 'Folder' || type === 'Workspace'">
                  <td class="label">Workspace</td>

                  <td class="value">
                    <SelectField v-model="workspace" :options="workspaceList" />
                  </td>
                </tr>

                <tr v-if="type === 'Folder'">
                  <td class="label">Folder</td>

                  <td class="value">
                    <SelectField v-model="folder" :options="folderList" />
                  </td>
                </tr>

                <tr v-if="type === 'Task'">
                  <td class="label">Task</td>

                  <td class="value">
                    <SelectField v-model="task" :options="taskList" />
                  </td>
                </tr>

                <tr v-if="type === 'Form'">
                  <td class="label">Form</td>

                  <td class="value">
                    <SelectField v-model="form" :options="formList" />
                  </td>
                </tr>

                <tr v-if="type === 'Workflow'">
                  <td class="label">Workflow</td>

                  <td class="value">
                    <SelectField v-model="workflow" :options="workflowList" />
                  </td>
                </tr>

                <tr v-if="type === 'User'">
                  <td class="label">User</td>

                  <td class="value">
                    <SelectField v-model="user" :options="userList" />
                  </td>
                </tr>

                <tr v-if="type === 'Group'">
                  <td class="label">Group</td>

                  <td class="value">
                    <SelectField v-model="group" :options="groupList" />
                  </td>
                </tr>

                <tr v-if="false">
                  <td class="label">Name</td>

                  <td class="value">
                    <TextField v-model="words" />
                  </td>
                </tr>
              </template>
              <tr v-if="false">
                <td class="label">Date from</td>

                <td class="value">
                  <DateField v-model="date" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>

      <div class="row items-center footer q-pa-sm">
        <div class="col-auto">
          <div v-if="true" class="row items-center date-filter">
            <div class="col-auto" style="width: 160px">
              <DateField
                v-model="fromDate"
                :hide-border="true"
                placeholder="From Date"
              ></DateField>
            </div>
            <div class="col-auto q-mx-sm">
              <BaseIcon name="eva-arrow-forward-outline" />
            </div>
            <div class="col-auto" style="width: 160px">
              <DateField
                v-model="toDate"
                :hide-border="true"
                placeholder="To Date"
              ></DateField>
            </div>
          </div>
        </div>
        <q-space />
        <div class="col-auto footer1 q-ml-sm">
          <div class="row items-center">
            <BaseButton is-flat label="reset" class="q-mr-sm" @click="reset" />

            <BaseButton label="search more" @click="searchFiles" />
          </div>
        </div>
      </div>

      <!-- ... -->
    </q-menu>
    <q-menu
      v-model="showSearchMenu"
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom end"
      self="top right"
      no-parent-event
      fit
      no-focus
      no-refocus
      class="shadow-3"
    >
      <!-- <div class="row justify-end">
          <BaseCloseButton />
        </div> -->
      <BaseScrollbar height="calc(80vh - 273px)" width="600px" class="q-pb-sm">
        <div id="search-menu">
          <div
            class="row items-center chip-menu q-pb-sm"
            style="border-bottom: 1px solid var(--divider-color)"
          >
            <template v-for="option in typeList">
              <div
                v-if="option.isVisible"
                :key="option.id"
                class="chip"
                :class="{ 'condition-chip': option.isSelectMenu }"
                @click="option.isSelectMenu = !option.isSelectMenu"
              >
                <BaseIcon
                  v-if="option.isSelectMenu"
                  name="eva-checkmark-outline"
                  class="q-mr-sm"
                />
                <div>{{ option.label }}</div>
              </div>
            </template>
            <div class="chip" @click="showFullTypeList">
              <div>
                {{ showAllButtonText }}
                <BaseIcon
                  name="mdi-menu-down"
                  :class="{ 'rotate-180': isShowAll }"
                />
              </div>
            </div>
          </div>

          <div
            class="row items-center q-py-md"
            style="border-bottom: 1px solid var(--divider-color)"
          >
            <BaseIcon name="eva-search" class="q-mr-sm" />
            {{ search }}
          </div>
          <!-- <BaseSheetLoading :is-active="isLoadingActive" /> -->

          <BaseLoadingBarSheet
            v-if="isLoadingActive"
            style="position: absolute !important; z-index: 9999"
          />

          <template v-if="searchResult.length">
            <div
              v-for="(result, index) in searchResult"
              :key="index"
              class="row items-center q-py-sm cursor-pointer"
              style="border-bottom: 1px solid var(--divider-color)"
              @click="goto(result)"
            >
              <div class="col-auto">
                <BaseIcon :name="getMenuIcon(result.menu)" class="q-mr-md" />
              </div>
              <div class="col">
                <div class="search-title">
                  {{ result.searchName }}
                </div>
                <div class="subtitle">
                  {{ result.description }}
                </div>
              </div>
              <div class="col-auto">{{ result.date }}</div>
            </div>
            <BaseSeparator />

            <div class="row items-center q-pa-sm">
              <div class="col subtitle">{{ totalItems }} results found</div>

              <div
                v-if="totalItems >= 10"
                class="show-all"
                @click="searchFiles"
              >
                {{ loadMore ? "view less" : "view all" }}
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="row items-center q-py-md"
              style="border-bottom: 1px solid var(--divider-color)"
            >
              <!-- <BaseIcon name="eva-search" class="q-mr-sm" /> -->
              No recent items match your search
            </div>
          </template>
        </div>
      </BaseScrollbar>

      <div class="row items-center footer q-pa-sm">
        <div class="col-auto">
          <div v-if="true" class="row items-center date-filter">
            <div class="col-auto" style="width: 160px">
              <DateField
                v-model="fromDate"
                :hide-border="true"
                placeholder="From Date"
              ></DateField>
            </div>
            <div class="col-auto q-mx-sm">
              <BaseIcon name="eva-arrow-forward-outline" />
            </div>
            <div class="col-auto" style="width: 160px">
              <DateField
                v-model="toDate"
                :hide-border="true"
                placeholder="To Date"
              ></DateField>
            </div>
          </div>
        </div>
        <q-space />
        <div class="col-auto footer1 q-ml-sm">
          <div class="row items-center">
            <BaseButton is-flat label="reset" class="q-mr-sm" @click="reset" />

            <BaseButton label="search more" @click="searchFiles" />
          </div>
        </div>
      </div>

      <!-- ... -->
    </q-menu>

    <FileSheet
      v-model="isFileSheetVisible"
      :file="selectFile"
      :repository-field="repositoryField"
      :options="{}"
      @show-files="isFileSheetVisible = true"
    />

    <RequestProcessSheet
      :workflow-request-list="workflowTicketList"
      :process-id="processId"
      :workflow="workflows"
      :workflow-id="workflowId"
      :form-id="formId"
      :form="forms"
      :enable-controls="_enableControls"
    />
  </div>
</template>

<script>
import SelectField from "@/components/common/form/select-field/SelectField";
import TextField from "@/components/common/form/text-field/TextField";
import DateField from "@/components/common/form/date-field/DateField.vue";

import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet";
import {
  workspace,
  repository,
  group,
  form,
  workflow,
  user,
  globalSearch,
  report,
} from "@/api/factory.js";
import RequestProcessSheet from "@/views/search/components/RequestProcessSheet";
import store from "@/store/index.js";

export default {
  name: "GlobalSearch",

  components: {
    SelectField,
    TextField,
    DateField,
    FileSheet,
    RequestProcessSheet,
  },

  data() {
    return {
      search: "",
      type: "",
      isShowAll: false,
      typeList: [
        {
          id: this.$nano.id(),
          label: "Document",
          value: "Documents",
          isSelectMenu: false,
          isVisible: true,
          default: true,
        },
        {
          id: this.$nano.id(),
          label: "Content",
          value: "content",
          isSelectMenu: true,
          isVisible: true,
          default: true,
        },
        {
          id: this.$nano.id(),
          label: "Request No",
          value: "Request No",
          isSelectMenu: false,
          isVisible: true,
          default: true,
        },
        {
          id: this.$nano.id(),
          label: "Workspace",
          value: "Workspace",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        {
          id: this.$nano.id(),
          label: "Folder",
          value: "Folders",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Task",
        //   value: "Task",
        // },
        {
          id: this.$nano.id(),
          label: "Form",
          value: "Form",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        {
          id: this.$nano.id(),
          label: "Workflow",
          value: "WorkFlow",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        {
          id: this.$nano.id(),
          label: "User",
          value: "User",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        {
          id: this.$nano.id(),
          label: "Group",
          value: "Group",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
        {
          id: this.$nano.id(),
          label: "Menu",
          value: "Menu",
          isSelectMenu: false,
          isVisible: false,
          default: false,
        },
      ],
      words: "",
      owner: "",
      itemName: "",
      location,
      date: "",
      workflow: "",
      folder: "",
      workspace: "",
      workspaceList: [],
      folderList: [],
      groupList: [],
      group: [],
      taskList: [],
      formList: [],
      form: "",
      task: "",
      workflowList: [],
      userList: [],
      user: "",
      isExpanded: false,
      fromDate: "",
      toDate: "",
      showSearchOptions: false,
      showSearchMenu: false,
      isSelectMenu: false,
      searchResult: [],
      selectFile: {},
      isFileSheetVisible: false,
      repositoryField: [],
      loadMore: false,
      totalItems: 0,
      searchInput: {},
      workflowTicketList: [],
      selectedProcess: {},
      processId: 0,
      workflows: {},
      workflowId: 0,
      formId: 0,
      forms: {},
      isLoadingActive: false,
      showAllButtonText: "More",
    };
  },

  computed: {
    // _searchResult() {
    //   let menu = this.typeList.filter((item) => item.isSelectMenu);
    //   // console.log(menu);
    //   if (menu.length) {
    //     let result = [];
    //     this.searchResult.forEach((item) => {
    //       let filter = menu.find((menu) => menu.value === item.menu);
    //       if (filter) {
    //         result.push(item);
    //       }
    //     });
    //     return result;
    //   }
    //   return this.searchResult;
    // },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    workspace() {
      if (this.workspace) {
        this.getRepositories();
      }
    },

    type() {
      this.workspace = "";
      this.workflow = "";
      this.user = "";
      this.group = "";
      this.task = "";
      this.form = "";
      this.folder = "";
      this.date = "";
      if (this.type === "Workspace" || this.type === "Folder") {
        this.getWorkspaces();
      } else if (this.type === "Group") {
        this.getGroups();
      } else if (this.type === "Form") {
        this.getForms();
      } else if (this.type === "Task") {
        this.getTasks();
      } else if (this.type === "Workflow") {
        this.getWorkflows();
      } else if (this.type === "User") {
        this.getUsers();
      }
    },

    // $route: {
    //   deep: true,
    //   immediate: true,
    //   handler() {

    //     if (this.$route.query) {
    //       if (this.$route.query.type) {
    //         this.type = this.$route.query.type;
    //         console.log("this.$route");
    //       }
    //     }
    //   },
    // },

    search() {
      if (this.search) {
        if (this.search.length >= 2) {
          this.showSearchMenu = true;

          this.getGlobalSearch();
        }
      } else {
        this.showSearchMenu = false;
      }
    },

    typeList: {
      deep: true,
      handler() {
        let menu = this.typeList.filter((item) => item.isSelectMenu);
        console.log(menu);
        let name = [];
        menu.forEach((item) => {
          name.push(item.value);
        });

        this.getGlobalSearch(name.length ? name : [""]);
      },
    },
  },

  created() {},

  methods: {
    showFullTypeList() {
      this.isShowAll = !this.isShowAll;
      this.showAllButtonText = this.isShowAll ? "Less" : "More";
      this.typeList.forEach((option) => {
        option.isVisible = this.isShowAll ? true : option.default;
      });
    },

    async getWorkspaces() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaceList = payload.map((workspace) => ({
        id: workspace.id,
        label: workspace.value,
        value: workspace.value,
      }));
    },

    async getRepositories() {
      if (this.workspace) {
        this.folderList = [];
        // this.$store.commit("showLoadingBar");
        let id = this.workspaceList.find(
          (item) => item.value === this.workspace
        )["id"];

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          id
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.folderList = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.value,
        }));
      }
    },

    async getGroups() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await group.getGroupList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // console.log(payload);

      this.groupList = payload.map((group) => ({
        id: this.$nano.id(),
        label: group.value,
        value: group.value,
      }));
    },

    async getForms() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // console.log(payload);

      this.formList = payload.map((group) => ({
        id: this.$nano.id(),
        label: group.value,
        value: group.value,
      }));
    },

    async getWorkflows() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflowList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // console.log(payload);

      this.workflowList = payload.map((group) => ({
        id: this.$nano.id(),
        label: group.value,
        value: group.value,
      }));
    },

    async getUsers() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await user.getUserList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      // console.log(payload);

      this.userList = payload.map((group) => ({
        id: this.$nano.id(),
        label: group.value || user.loginName,
        value: group.value || user.loginName,
      }));
    },

    async getTasks() {
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntries(19, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      console.log(data);

      this.taskList = data || [];
      if (data.length) {
        if (data[0].value.length) {
          // data[0].value.forEach(item => {
          //    this.taskList.push({
          //   id: this.$nano.id(),
          //   label: item.
          // })
          // });
        }
      }
    },

    searchFiles() {
      let name = "";
      if (this.type === "Workspace") {
        name = this.workspace;
      } else if (this.type === "Form") {
        name = this.form;
      } else if (this.type === "Folder") {
        name = this.folder;
      } else if (this.type === "Task") {
        name = this.task;
      } else if (this.type === "Workflow") {
        name = this.workflow;
      } else if (this.type === "User") {
        name = this.user;
      } else if (this.type === "Group") {
        name = this.group;
      }
      if (this.showSearchOptions) {
        this.$router.replace({
          name: "search",
          query: { type: this.type, name: name },
        });
      } else {
        this.searchInput.fromDate = this.fromDate;
        this.searchInput.toDate = this.toDate;
        if (this.$route.name === "search") {
          this.$route.params.searchInput = this.searchInput;
          store.commit("setSearchInput", this.searchInput);
        } else {
          this.$router.replace({
            name: "search",
            params: {
              searchInput: this.searchInput,
            },
          });
        }
      }

      this.showSearchMenu = false;
    },

    async getGlobalSearch(menu) {
      let type = this.typeList.filter((item) => item.isSelectMenu);
      // console.log(menu);
      let name = [];
      type.forEach((item) => {
        name.push(item.value);
      });
      let menus = [];
      if (menu) {
        menus = menu;
      } else if (type.length) {
        menus = name;
      } else {
        menus = [""];
      }

      let input = {
        SearchValue: this.search,
        type: menus,
        itemsPerPage: 10,
        currentPage: 1,
        fromDate: this.fromDate,
        toDate: this.toDate,
        userId: this.$store.state.session.id,
      };
      this.searchInput = input;
      this.isLoadingActive = true;

      const { error, payload } = await globalSearch.globalSearch(input);

      this.isLoadingActive = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);

      let res = payload;
      // console.log((res));
      this.totalItems = payload.meta.totalItems;

      if (res.data) {
        console.log(res.data);
        if (res.data.length) {
          this.searchResult = [];
          // this.searchResult = res.resultJson;
          res.data.forEach((result) => {
            this.searchResult.push({
              menu: result.type,
              searchName:
                result.type === "Documents" ? result.ifileName : result.name,
              idJson: result.id,
              description: result.description
                ? result.description
                : result.type,
              requestNo: result.requestNo,
              date: result.modifiedDateandtime
                ? this.$day.format(result.modifiedDateandtime)
                : this.$day.format(result.dateandtime),
            });
          });
        } else {
          this.searchResult = [];
        }
      }
    },

    getMenuIcon(menu) {
      switch (menu) {
        case "Folders":
          return "eva-archive-outline";
        case "WorkFlow":
          return "o_account_tree";
        case "Task":
          return "eva-cube-outline";
        case "Portal":
          return "eva-globe-outline";
        case "Form":
          return "mdi-text-box-outline";
        case "Settings":
          return "eva-settings-2-outline";
        case "Report":
          return "eva-pie-chart-outline";
        case "Documents":
          return "mdi-file-document-outline";
        case "Menu":
          return "mdi-menu";
        case "Content":
          return "mdi-file-document-outline";
        case "Request No":
          return "o_account_tree";

        default:
          return "o_dashboard";
      }
    },

    goto(result) {
      console.log(result);
      if (result.idJson.itemId && result.menu !== "Request No") {
        this.getRepositoryField(result.idJson.repositoryId);
        this.getFile(result.idJson.repositoryId, result.idJson.itemId);
      } else if (result.idJson.workflwId && result.menu === "Request No") {
        this.getWorkflowData(result.idJson.workflowId, result.idJson.processId);
      } else {
        if (result.menu === "Folders") {
          this.$router.replace({
            name: "repositories-browse",
            query: {
              workspaceId: result.idJson.workspaceId,
              repositoryId: result.idJson.repositoryId,
            },
          });
          // if (this.$route.name === "repositories-browse") {
          //   this.$router.replace({
          //     name: "repositories-browse",
          //     query: {
          //       workspaceId: result.workspaceId,
          //       repositoryId: result.id,
          //     },
          //   });
          // } else {
          //   this.$router.push({
          //     name: "repositories-browse",
          //     query: {
          //       workspaceId: result.workspaceId,
          //       repositoryId: result.id,
          //     },
          //   });
          // }
        } else if (result.menu === "WorkFlow") {
          this.$router.replace({
            name: "workflow-builder",
            params: { id: String(result.idJson.workflowId) },
          });
        } else if (result.menu === "Menu") {
          this.$router.replace({
            name: result.searchName,
          });
        }
      }
    },

    async getFile(rId, itemId) {
      // this.$store.commit("showLoadingBar");

      let input = {
        repositoryId: rId,
        parentNodeId: 0,
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                id: this.$nano.id(),
                criteria: "itemid",
                condition: "IS_EQUALS_TO",
                value: itemId,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        currentPage: 1,
        itemsPerPage: 100,
        mode: "BROWSE",
        level: 1,
      };
      const { error, payload } = await repository.getEntities(input);

      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload.data[0].value.length) {
        let data = payload.data[0].value;
        console.log(data);

        let file = data.find((item) => {
          return item.id === Number(itemId);
        });
        console.log(file);
        this.selectFile = file;
        this.isFileSheetVisible = true;
      }
    },

    async getRepositoryField(id) {
      const { error, payload } = await repository.getRepositoryField({
        criteria: "repositoryId",
        value: id,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryField = payload;
    },

    async getWorkflowData(id, pId) {
      // this.$store.commit("showLoadingBar");
      this.workflowTicketList = [];

      // this.list = [];
      const { error, payload } = await report.getWorkflowData(id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
        mode: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.workflowTicketList.push({
            ...process,
          });
        });
      });

      console.log(this.workflowTicketList);

      this.selectedProcess = this.workflowTicketList.find(
        (item) => item.processId === Number(pId)
      );

      await this.getWorkflow(id, pId);
    },

    async getWorkflow(id, pId) {
      this.workflowId = id;
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(id);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      console.log(payload.wFormId);

      this.workflows = payload;

      this.formId = String(payload.wFormId);
      await this.getForm(pId);
      // let workflowJson = JSON.parse(this.workflow.flowJson);
    },

    async getForm(pId) {
      if (this.formId) {
        // this.$store.commit("showLoadingBar");

        const { error, payload } = await form.getForm(this.formId);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        let formJson = JSON.parse(payload.formJson);
        this.forms = formJson;
      }

      this.processId = Number(pId);
    },

    reset() {
      this.search = "";
      this.fromDate = "";
      this.toDate = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/styles/core/typography.scss";

#settings-card {
  .icon {
    color: var(--secondary);
  }

  .action {
    margin-right: -8px;
  }

  .children {
    height: 0px;
    overflow: hidden;
    transition: all 0.25s ease;

    &.is-expanded {
      padding: 4px 0px;
      height: auto;
    }
  }
}

#global-search {
  width: 600px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0px 12px;
  color: var(--icon-color);
  background-color: var(--body-bg-color);

  .label {
    @extend .text-sm;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
}

#search-menu {
  padding: 14px;
  width: 600px;

  .chip {
    font-weight: 500;
    // background-color: var(--component-bg-color-inferior);
    padding: 4px 8px;
    border-radius: 6px;
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--component-bg-color-inferior);
    cursor: pointer;
  }

  .condition-chip {
    background-color: #e0f5fa !important;
    border: 1px solid #e0f5fa;
  }

  .condition-chip:hover {
    background-color: #d5f0f7 !important;
  }

  .title {
    @extend .title-2;
    text-transform: capitalize;
  }

  .subtitle {
    color: var(--icon-color-inverted);
  }

  .search-title {
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.25s ease;
    color: var(--title-3-color);

    &.highlight {
      font-weight: bold;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      // vertical-align: top;
      padding: 8px 4px;
    }
  }

  .label {
    color: var(--title-3-color);
    width: 150px;
    font-weight: 500;
  }

  // .value {
  //   // font-weight: 500;
  // }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding: 16px;
  border-top: 1px solid var(--divider-color);

  .date-filter {
    border: 1px solid var(--divider-color);
    // width: 80px;
  }
}

.show-all {
  color: var(--secondary);
  text-decoration: underline;
  font-weight: medium;
  cursor: pointer;
  font-size: 12px;
}

#loading {
  align-items: center;
  cursor: pointer;
  position: absolute;
  justify-content: center;
}
</style>
