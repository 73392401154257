<template>
  <q-dialog :value="value" @input="close">
    <div id="quickhelpmodal" :style="{ width }">
      <!-- header -->

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar :height="scrollBarHeight">
        <BaseSheetLoading v-if="loadingBarSave" />

        <slot name="default"></slot>
      </BaseScrollbar>

      <!-- ... -->

      <!-- footer -->

      <div v-if="hasFooter && !loadingBarSave" class="footer">
        <slot name="footer"></slot>
      </div>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Modal",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    hasFooter: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: "320px",
    },

    height: {
      type: String,
      default: "80vh",
    },

    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["loadingBarSave"]),

    scrollBarHeight() {
      if (this.height === "auto") {
        return this.height;
      }

      const negativeHeight = this.hasFooter ? 138 : 69;
      return `calc(${this.height} - ${negativeHeight}px)`;
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#quickhelpmodal {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  max-width: 150vw;
  max-height: 99vh;
  margin-left: 30px;
  margin-right: 30px;
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--divider-color);
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }
}
</style>
