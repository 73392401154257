<template>
  <div id="logo" class="cursor-pointer" @click="homePage">
    <img :src="_logo" :height="logoHeight" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",

  computed: {
    ...mapState(["darkTheme"]),

    _logo() {
      // if (this.darkTheme) {
      //   return "https://demo.ezofis.com/Logo/name-dark.png";
      // } else {
      //   return "https://demo.ezofis.com/Logo/name-light.png";
      // }
      return require(`@/assets/logo/name-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },

    logoHeight() {
      return "24px";
    },
  },

  methods: {
    homePage() {
      if (this.$store.state.session.profile) {
        if (this.$store.state.profileMenus.length) {
          if (
            this.$store.state.profileMenus.find((row) => row.workspaces)?.Menu[
              "Overview"
            ]
          ) {
            if (this.$route.name !== "repositories-browse") {
              this.$router.push({ name: "repositories-browse" });
            }
            return;
          }
        }
      }
      if (this.$route.name !== "workflows-overview") {
        this.$router.push({ name: "workflows-overview" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#logo {
  display: flex;
  align-items: center;

  // img {
  //   height: 24px;
  // }
}
</style>
