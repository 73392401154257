import { render, staticRenderFns } from "./Stepper.vue?vue&type=template&id=e7bbefd4&scoped=true&"
import script from "./Stepper.vue?vue&type=script&lang=js&"
export * from "./Stepper.vue?vue&type=script&lang=js&"
import style0 from "./Stepper.vue?vue&type=style&index=0&id=e7bbefd4&prod&lang=scss&scoped=true&"
import style1 from "./Stepper.vue?vue&type=style&index=1&id=e7bbefd4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7bbefd4",
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep});
