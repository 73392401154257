<template>
  <div class="">
    <q-stepper
      ref="stepper"
      v-model="step"
      alternative-labels
      color="primary"
      animated
      class=""
    >
      <q-step
        v-for="(level, index) in stageLevel"
        :key="level.id"
        :name="level.id"
        :title="level.label"
        :caption="level.status"
        :prefix="index + 1"
        :done="currentStep >= index + 1"
        :done-color="doneColor"
      >
      </q-step>
    </q-stepper>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Stepper",

  props: {
    stageLevel: {
      type: Array,
      default: () => [],
    },

    currentStep: {
      type: Number,
      default: 0,
    },

    doneColor: {
      type: String,
      default: "",
    },
  },

  setup() {
    return {
      step: ref(1),
    };
  },
};
</script>
<style lang="scss" scoped>
// .stepper {
//   border: 1px solid var(--divider-color);
//   border-radius: 6px;
// }
</style>
<style>
.q-stepper {
  box-shadow: none !important;
}

.q-stepper__header--border {
  border-bottom: 0px solid #fff !important;
}

.q-stepper__header--alternative-labels .q-stepper__tab {
  min-height: 0px !important;
  padding: 6px 24px !important;
}

.q-stepper__caption {
  color: var(--secondary);
}
</style>
