<template>
  <div id="video-card" @click.stop="$emit('click')">
    <div class="description">
      {{ caption }}
    </div>

    <div
      v-if="pageHelp === 'WORKFLOW'"
      style="
        position: relative;
        padding-bottom: calc(50.70717423133236% + 42px);
        height: 0;
      "
      class="expand"
    >
      <iframe
        src="https://walk.ezofis.com/embed/clxbirpmz0rgat2oea5devc2m"
        title="Workflow - Process Design"
        allow="clipboard-write"
        frameborder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
      <div class="play-btn shadow-2" @click="$emit('videoclick')"></div>
    </div>
    <div
      v-else-if="pageHelp === 'FORM'"
      style="
        position: relative;
        padding-bottom: calc(50.70717423133236% + 42px);
        height: 0;
      "
      class="expand"
    >
      <iframe
        src="https://app.supademo.com/embed/clxd8vlxd1by1t2oe6icxf5nr"
        title="Workflow - Process Design"
        allow="clipboard-write"
        frameborder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
      <div class="play-btn shadow-2" @click="$emit('videoclick')"></div>
    </div>

    <div
      v-else-if="pageHelp === 'REPOSITORY'"
      style="
        position: relative;
        padding-bottom: calc(50.70717423133236% + 42px);
        height: 0;
      "
      class="expand"
    >
      <iframe
        src="https://app.supademo.com/embed/clxqan0n800m3tj3fz0zayzcf"
        title="Workflow - Process Design"
        allow="clipboard-write"
        frameborder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
      <div class="play-btn shadow-2" @click="$emit('videoclick')"></div>
    </div>

    <div v-else class="video">
      <img :src="_thumbnail" alt="video" />

      <div class="play-btn shadow-2">
        <BaseIcon name="mdi-play" size="24px" color="white" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VideoCard",

  props: {
    caption: {
      type: String,
      required: true,
    },

    thumbnail: {
      type: String,
      required: true,
    },

    pageHelp: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    _thumbnail() {
      return require(`@/assets/thumbnail/${this.thumbnail}-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
#video-card {
  .description {
    margin: 16px 0px;
  }

  .video {
    position: relative;
    border: 1px solid var(--border-color);
    overflow: hidden;
    height: 120px;

    img {
      width: 100%;
      object-fit: fill;
    }

    .play-btn {
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      height: 36px;
      width: 36px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--secondary);
    }
  }

  .expand {
    position: relative;
    border: 1px solid var(--border-color);
    overflow: hidden;
    height: 120px;
    .play-btn {
      position: absolute;
      height: 210px;
      width: 500px;
      display: flex;
      justify-content: center;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
