<template>
  <div
    id="expandable-search"
    v-tooltip.top="'search'"
    class="is-expanded"
    @click="toggle"
  >
    <!-- :class="{ 'is-expanded': isExpanded || searchQuery }" -->
    <BaseIcon name="eva-search" class="icon" />

    <input
      ref="expandableSearch"
      v-model="searchQuery"
      type="text"
      placeholder="Search"
      @keyup="$emit('search', searchQuery)"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
export default {
  name: "ExpandableSearch",

  data() {
    return {
      isExpanded: false,
      searchQuery: "",
    };
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded) {
        this.$refs.expandableSearch.focus();
      }
    },

    handleBlur() {
      this.isExpanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#expandable-search {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--divider-color);
  border-radius: 36px;
  overflow: hidden;
  transition: width 0.25s ease;

  .icon {
    padding: 0px 8px;
  }

  &.is-expanded {
    width: 240px;
    justify-content: flex-start;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.is-expanded):hover {
    background-color: var(--hover-bg-color);
  }
}
</style>
