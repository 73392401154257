<template>
  <div id="view-action-bar">
    <!-- tabs -->

    <slot name="header-title"></slot>

    <Tabs :tab="tab" :tabs="tabs" />

    <!-- ... -->

    <q-space />

    <!-- actions -->

    <PortalTarget v-if="hideActionbar" name="action-bar" slim />

    <!-- ... -->
  </div>
</template>

<script>
import { PortalTarget } from "portal-vue";

import Tabs from "@/components/common/tabs/RouteTabs.vue";

export default {
  name: "ActionBar",

  components: { Tabs, PortalTarget },

  props: {
    tab: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },

    hideActionbar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#view-action-bar {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-bottom: 1px solid var(--divider-color);
}
</style>
