<template>
  <div id="view-header">
    <div>
      <!-- title -->

      <div class="title-1 q-mb-xs">
        {{ title }}
      </div>

      <!-- ... -->

      <!-- breadcrumbs -->

      <Breadcrumbs :breadcrumbs="breadcrumbs" />

      <!-- ... -->
    </div>

    <q-space />

    <!-- quick help -->

    <BaseActionButton
      icon="eva-question-mark"
      color="secondary"
      @click="$parent.$emit('quickHelp')"
    />

    <!-- ... -->

    <!-- actions -->

    <slot name="actions"></slot>

    <!-- ... -->
  </div>
</template>

<script>
import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";

export default {
  name: "Header",

  components: { Breadcrumbs },

  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#view-header {
  display: flex;
  align-items: center;
}
</style>
