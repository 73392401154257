var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Sheet',{attrs:{"width":"30vw","height":"80vh","custom-height":_vm.selectedNode === 'inbox' ||
      _vm.selectedNode === 'common' ||
      _vm.selectedNode === 'payment' ||
      _vm.initiateProcess ||
      _vm.isPortal
        ? '30'
        : '0',"no-padding":""},on:{"input":_vm.closeCommentsSheet},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Comments ")]},proxy:true},{key:"actions",fn:function(){return [(_vm.selectedProcess.processId)?_c('div',{staticClass:"q-ml-sm text-gray q-mr-sm ellipsis",staticStyle:{"font-size":"10px"}},[_vm._v(" auto-refresh every 30 seconds ")]):_vm._e(),(_vm.selectedProcess.processId)?_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('refresh'),expression:"'refresh'",modifiers:{"top":true}}],staticClass:"q-mr-sm",attrs:{"icon":"mdi-refresh","color":"secondary","no-border":"","is-flat":"","size":"20px"},on:{"click":function($event){return _vm.getComments(0)}}}):_vm._e()]},proxy:true}]),model:{value:(_vm.commentsSheet),callback:function ($$v) {_vm.commentsSheet=$$v},expression:"commentsSheet"}},[_c('div',{attrs:{"id":"content-wrapper"}},[_c('BaseScrollbar',{attrs:{"height":_vm.commentsSheetHeight}},[_vm._l((_vm.commentsList),function(row,idx){return [_c('q-chat-message',{key:idx,staticClass:"q-ma-sm",attrs:{"name":row.createdBy === _vm.$store.state.session.id
                ? 'me'
                : row.createdByEmail || row.externalCommentsby,"text":[row.comments],"text-html":"","sent":row.createdBy === _vm.$store.state.session.id,"stamp":_vm.lastCommentDuration(row),"bg-color":row.createdBy === _vm.$store.state.session.id
                ? 'primary-1'
                : 'secondary-1'},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [(row.embedFileList && row.embedFileList.length)?_c('div',{staticClass:"file-icons"},[_c('FileIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(row.embedFileList[0].fileName),expression:"row.embedFileList[0].fileName",modifiers:{"top":true}}],key:`Icon-${idx}`,staticClass:"file-icon q-mr-sm",attrs:{"mime-type":_vm.fileType(row.embedFileList[0].fileName)}})],1):_vm._e()]},proxy:true}],null,true)})]})],2)],1),(
        _vm.selectedNode === 'inbox' ||
        _vm.selectedNode === 'common' ||
        _vm.selectedNode === 'payment' ||
        _vm.initiateProcess ||
        _vm.isPortal
      )?_c('div',{staticClass:"col borderTop"},[_c('div',{staticClass:"row"},[(_vm.checkTenant())?[_c('div',{staticClass:"col actions"},[(_vm.attachmentList.length && _vm.fileNames.length)?_c('div',{staticClass:"row select-action q-pa-sm action-container"},[_c('CheckboxField',{staticClass:"q-pr-sm",class:_vm.attachComment ? 'col-auto' : 'col',attrs:{"label":"Add Comment to PDF"},model:{value:(_vm.attachComment),callback:function ($$v) {_vm.attachComment=$$v},expression:"attachComment"}}),(
                  _vm.selectedProcess.processId &&
                  _vm.selectedProcess.stageType !== 'START' &&
                  !_vm.attachComment &&
                  !_vm.isPortal
                )?_c('CheckboxField',{staticClass:"col-auto notify-cmd",attrs:{"label":"Notify Initiator/Requester"},model:{value:(_vm.notifyInitiator),callback:function ($$v) {_vm.notifyInitiator=$$v},expression:"notifyInitiator"}}):_vm._e(),(_vm.attachComment)?_c('SelectField',{staticClass:"col field comment-attach attach-cmd",attrs:{"is-searchable":true,"options":_vm.fileNames},model:{value:(_vm.selectedFileForAttach),callback:function ($$v) {_vm.selectedFileForAttach=$$v},expression:"selectedFileForAttach"}}):_vm._e()],1):_vm._e(),(
                _vm.selectedProcess.processId &&
                _vm.selectedProcess.stageType !== 'START' &&
                (_vm.attachComment ||
                  !(_vm.attachmentList.length && _vm.fileNames.length)) &&
                !_vm.isPortal
              )?_c('CheckboxField',{staticClass:"col-12 q-pl-sm q-pb-sm q-pt-sm",class:_vm.notifyInitiatorClass,attrs:{"label":"Notify Initiator/Requester"},model:{value:(_vm.notifyInitiator),callback:function ($$v) {_vm.notifyInitiator=$$v},expression:"notifyInitiator"}}):_vm._e(),(_vm.displayInternalPublic)?_c('SingleChoiceField',{staticClass:"action-container",class:{
                'internal-public': _vm.attachmentList.length > 0,
                'col q-pa-sm': true,
                'internal-public-margin': _vm.attachComment,
              },attrs:{"options":_vm.commentActions,"show-options-wrapper":false,"options-per-line":2},model:{value:(_vm.commentAction),callback:function ($$v) {_vm.commentAction=$$v},expression:"commentAction"}}):_vm._e()],1)]:_vm._e(),(_vm.isTenantGOTOComments())?[_c('SingleChoiceField',{staticClass:"col q-mb-sm actions",attrs:{"options":_vm.jiraCommentActions,"options-per-line":2},model:{value:(_vm.jiraCommentAction),callback:function ($$v) {_vm.jiraCommentAction=$$v},expression:"jiraCommentAction"}})]:_vm._e()],2),_c('div',{staticClass:"row text-area-action q-ml-sm q-mr-sm"},[_c('div',{staticClass:"col"},[_c('TextAreaField',{attrs:{"auto-focus":"","placeholder":"Type a new comment..."},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}})],1),_c('div',{staticClass:"col-auto",staticStyle:{"margin":"auto","margin-right":"8px"}},[_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Post Comments'),expression:"'Post Comments'",modifiers:{"left":true}}],staticClass:"q-ml-sm comment-actions",attrs:{"icon":"eva-paper-plane-outline","color":"primary-11","size":"16px","rotate-class":"rotate-45"},on:{"click":_vm.saveComment}}),_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Advanced Comments'),expression:"'Advanced Comments'",modifiers:{"left":true}}],staticClass:"q-ml-sm q-mt-sm comment-actions",attrs:{"icon":"mdi-comment-text-multiple","color":"primary-11","size":"16px"},on:{"click":function($event){_vm.advancedCommentsSheet = true}}})],1)])]):_vm._e()]),_c('Confirm',{attrs:{"icon":"eva-alert-circle-outline","icon-color":"red"},on:{"input":function($event){{
        _vm.errorModal = false;
        _vm.errorMessage = '';
      }}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Error Message")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"q-py-md"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('BaseButton',{attrs:{"label":"Close","color":"red"},on:{"click":function($event){{
            _vm.errorModal = false;
            _vm.errorMessage = '';
          }}}})]},proxy:true}]),model:{value:(_vm.errorModal),callback:function ($$v) {_vm.errorModal=$$v},expression:"errorModal"}}),_c('Modal',{attrs:{"width":"60vw","height":"80vh","has-footer":""},on:{"input":function($event){{
        _vm.saveAction = '';
        _vm.workflowFormModel = {};
        _vm.advancedCommentsSheet = false;
      }}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Comments")]},proxy:true},{key:"default",fn:function(){return [_c('TextBuilderField',{model:{value:(_vm.advancedCommentText),callback:function ($$v) {_vm.advancedCommentText=$$v},expression:"advancedCommentText"}})]},proxy:true},{key:"footer",fn:function(){return [(_vm.checkTenant())?[(_vm.attachmentList.length && _vm.fileNames.length)?_c('div',{staticClass:"row actions advance-cmd"},[_c('CheckboxField',{staticClass:"col-auto q-pr-sm",staticStyle:{"margin":"auto"},attrs:{"label":"Add Comment to PDF"},model:{value:(_vm.attachComment),callback:function ($$v) {_vm.attachComment=$$v},expression:"attachComment"}}),(_vm.attachComment)?_c('SelectField',{staticClass:"field comment-attach",attrs:{"is-searchable":true,"options":_vm.fileNames},model:{value:(_vm.selectedFileForAttach),callback:function ($$v) {_vm.selectedFileForAttach=$$v},expression:"selectedFileForAttach"}}):_vm._e(),(
              _vm.selectedProcess.processId &&
              _vm.selectedProcess.stageType !== 'START' &&
              !_vm.isPortal
            )?_c('CheckboxField',{staticClass:"col-auto q-pl-sm adv-notify-cmd",attrs:{"label":"Notify Initiator/Requester"},model:{value:(_vm.notifyInitiator),callback:function ($$v) {_vm.notifyInitiator=$$v},expression:"notifyInitiator"}}):_vm._e()],1):_vm._e(),(_vm.checkTenant() && _vm.displayInternalPublic)?_c('SingleChoiceField',{staticClass:"actions q-mr-sm noborder",attrs:{"options":_vm.commentActions,"options-per-line":2},model:{value:(_vm.commentAction),callback:function ($$v) {_vm.commentAction=$$v},expression:"commentAction"}}):_vm._e()]:_vm._e(),(_vm.isTenantGOTOComments())?[_c('SingleChoiceField',{staticClass:"col q-mb-sm actions",attrs:{"options":_vm.jiraCommentActions,"options-per-line":2},model:{value:(_vm.jiraCommentAction),callback:function ($$v) {_vm.jiraCommentAction=$$v},expression:"jiraCommentAction"}})]:_vm._e(),_c('BaseButton',{attrs:{"label":"Post Comments","icon":"eva-paper-plane-outline","color":"primary"},on:{"click":_vm.saveAdvancedComment}})]},proxy:true}]),model:{value:(_vm.advancedCommentsSheet),callback:function ($$v) {_vm.advancedCommentsSheet=$$v},expression:"advancedCommentsSheet"}}),_c('Confirm',{attrs:{"icon":"eva-alert-circle-outline","icon-color":"orange"},on:{"input":function($event){_vm.confirmComment = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Public Message")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"q-py-md"},[_c('p',[_vm._v(" By selecting this message for public viewing, you acknowledge it will be seen by external users. Please review the content for appropriateness. Would you like to go ahead? ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('BaseButton',{staticClass:"q-mr-sm",attrs:{"is-flat":"","label":"No","color":"orange"},on:{"click":function($event){{
            _vm.commentAction = 2;
            _vm.confirmComment = false;
          }}}}),_c('BaseButton',{attrs:{"label":"Yes","color":"orange"},on:{"click":function($event){_vm.confirmComment = false}}})]},proxy:true}]),model:{value:(_vm.confirmComment),callback:function ($$v) {_vm.confirmComment=$$v},expression:"confirmComment"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }