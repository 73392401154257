<template>
  <q-header id="toolbar">
    <q-toolbar class="no-padding" style="height: 56px">
      <Legend />

      <template v-if="customerLogo">
        <CustomerLogo class="q-ml-md q-mr-xl" />
      </template>
      <template v-else>
        <Logo class="q-ml-md q-mr-xl" />
      </template>

      <GlobalSearch />

      <q-space />

      <template v-if="customerLogo && ezofisLogo">
        <Logo class="q-ml-md q-mr-md" />
      </template>
      <template v-else>
        <CompanyLogo class="q-mr-sm" />
      </template>

      <ThemeSwitcher class="q-mr-sm" />

      <Notifications class="q-mr-sm" />

      <BaseActionButton
        v-tooltip.bottom="'logout'"
        is-flat
        icon="eva-power-outline"
        no-border
        class="q-mr-sm"
        @click="logOut"
      />

      <Account class="q-mr-md" />
    </q-toolbar>
  </q-header>
</template>

<script>
import Legend from "./components/Legend.vue";
import Logo from "./components/Logo.vue";
import CustomerLogo from "./components/CustomerLogo.vue";
import GlobalSearch from "./components/GlobalSearch.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import Notifications from "./components/Notifications.vue";
import Account from "./components/Account.vue";
import CompanyLogo from "./components/CompanyLogo.vue";

export default {
  name: "Toolbar",

  components: {
    Legend,
    Logo,
    CustomerLogo,
    GlobalSearch,
    ThemeSwitcher,
    Notifications,
    Account,
    CompanyLogo,
  },

  computed: {
    customerLogo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "http://172.16.1.118"
      ) {
        return true;
      } else {
        return false;
      }
    },

    ezofisLogo() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    logOut() {
      window.localStorage.clear();
      //this.$router.push({ name: "signIn" });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbar {
  background-color: var(--component-bg-color);
}
</style>
