<template>
  <AppLayout>
    <ViewWrapper
      title="folders"
      :breadcrumbs="breadcrumbs"
      :tab="tab"
      :tabs="_tabs"
      :minimum-file-sheet="minimumFileSheetData"
      @quickHelp="showQuickHelp"
      @handleOpenFullFileSheet="openFullFileSheet"
      @hideFileSheet="closeFileSheet"
    >
      <!-- header -->

      <template #header-actions>
        <PortalTarget name="primary-action" slim />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp
        v-model="quickHelp"
        :page-help="'REPOSITORY'"
        @videoclick="videoHelp = true"
      />

      <!-- ... -->

      <QuickHelpModal
        v-model="videoHelp"
        :is-header="false"
        width="70vw"
        height="96vh"
        has-footer
      >
        <!-- Content -->

        <template #default>
          <div
            style="
              position: relative;
              padding-bottom: calc(50.70717423133236% + 42px);
              height: 0;
            "
            class="expand"
          >
            <iframe
              src="https://app.supademo.com/embed/clxqan0n800m3tj3fz0zayzcf"
              title="Workflow - Process Design"
              allow="clipboard-write"
              frameborder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </template>

        <template #footer>
          <BaseButton
            label="Close"
            icon="mdi-close"
            color="primary"
            @click="videoHelp = false"
          />
        </template>
        <!-- ...-->
      </QuickHelpModal>
      <!-- ...-->

      <router-view
        :full-file-sheet="fullFileSheet"
        :hide-file-sheet="hideFileSheet"
        @minimumFileSheet="minimumFileSheet"
      />

      <!-- child routes -->

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import { PortalTarget } from "portal-vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import QuickHelpModal from "@/components/common/popup/QuickHelpModal.vue";

export default {
  name: "Repositories",

  components: {
    AppLayout,
    ViewWrapper,
    PortalTarget,
    QuickHelp,
    QuickHelpModal,
  },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          icon: "mdi-view-dashboard-outline",
          route: "repositories-overview",
          access: false,
          accessLabel: "Overview",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "repositories-browse",
          access: true,
          accessLabel: "Manage Folders",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "repositories-trash",
          access: true,
          accessLabel: "Manage Folders",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "deleted files",
        //   icon: "eva-trash-2-outline",
        //   route: "file-trash",
        //   access: false,
        //   accessLabel: "Manage Folders",
        // },
        {
          id: this.$nano.id(),
          label: "reports",
          icon: "eva-trash-2-outline",
          route: "repositories-reports-overview",
          access: false,
          accessLabel: "Audit Report",
        },
      ],
      tab: "repositories-browse",
      quickHelp: false,
      minimumFileSheetData: {},
      fullFileSheet: {},
      hideFileSheet: false,
      videoHelp: false,
    };
  },

  computed: {
    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.folders
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    minimumFileSheet(file) {
      this.minimumFileSheetData = file;
    },

    openFullFileSheet(file) {
      this.fullFileSheet = file;
    },

    closeFileSheet(value) {
      this.hideFileSheet = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
