<template>
  <q-layout view="hHh LpR fFf">
    <!-- toolbar -->

    <Toolbar />

    <!-- ... -->

    <!-- drawer -->

    <Drawer />

    <!-- ... -->

    <!-- page content -->

    <q-page-container>
      <BaseScrollbar
        height="calc(100vh - 56px)"
        class="q-pa-md relative-position"
      >
        <slot name="default"></slot>
      </BaseScrollbar>
    </q-page-container>

    <!-- ... -->
  </q-layout>
</template>

<script>
import Toolbar from "./components/toolbar/Toolbar.vue";
import Drawer from "./components/drawer/Drawer.vue";

export default {
  name: "AppLayout",

  components: { Toolbar, Drawer },

  created() {
    // window.onbeforeunload = function (event) {
    //   event.preventDefault();
    //   event.returnValue =
    //     "Are you sure you want to leave the site? Please save any changes before leaving";
    // };
  },
};
</script>

<style lang="scss" scoped></style>
