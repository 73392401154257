<template>
  <Sheet :value="value" width="40vw" @input="$emit('input', false)">
    <!-- title -->

    <template #title> Metadata </template>
    <div id="file-details">
      <div id="item-overview">
        <table>
          <tbody>
            <tr v-for="data in fileData" :key="data.name" class="tableRow">
              <td class="label">{{ data.name }}</td>

              <td v-if="data.type !== 'TABLE'" class="value">
                <slot :name="data.name" :value="data.value">
                  {{ data.value || "-" }}
                </slot>
              </td>
              <td v-else class="value" style="padding: 8px">
                <slot :name="data.name">
                  <template v-if="data.value">
                    <BaseActionButton
                      icon="mdi-table"
                      is-flat
                      no-border
                      @click="showTable(data.name)"
                    />
                  </template>
                  <template v-else>
                    <BaseActionButton
                      icon="mdi-table"
                      is-flat
                      no-border
                      is-disabled
                    />
                  </template>
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        v-model="expandTableModal"
        width="60vw"
        height="80vh"
        @input="expandTableModal = false"
      >
        <!-- title -->

        <template #title>{{ tableHeader }}</template>

        <!-- ... -->

        <template #default>
          <div id="tableData">
            <template v-if="tableData.cellvalues">
              <table>
                <tr v-for="(row, idx) in tableData.cellvalues" :key="idx">
                  <th
                    :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                    v-for="cell in row"
                    :key="`r${idx}_c${cell.columnindex}`"
                  >
                    {{ cell.cellvalue }}
                  </th>
                </tr>
              </table>
            </template>
            <template v-else>
              <table>
                <thead>
                  <tr>
                    <th v-for="(rowValue, key) in tableData[0]" :key="key">
                      {{ key }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in tableData" :key="index">
                    <td v-for="(rowValue, key) in row" :key="key">
                      {{ rowValue }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </template>
      </Modal>
    </div>
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import Modal from "@/components/common/popup/Modal.vue";
export default {
  name: "FileDetails",

  components: { Sheet, Modal },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    fileData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      expandTableModal: false,
      tableHeader: "",
      tableData: [],
    };
  },

  methods: {
    showTable(name) {
      this.expandTableModal = true;
      let data = this.fileData.filter((item) => item.name === name);
      console.log(data);
      this.tableHeader = data[0].name;
      this.tableData = JSON.parse(data[0].value);
      console.log(this.tableData);
    },
  },
};
</script>

<style lang="scss" scoped>
#file-details {
  .field-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    .bot-recommendations {
      margin: 30px 0px 0px 8px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

#item-overview {
  padding-left: 16px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      vertical-align: top;
      padding: 16px 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .label {
    color: var(--icon-color);
    width: 200px;
  }

  .value {
    font-weight: 500;
  }

  .tableRow:hover {
    background: $secondary-1;
    cursor: pointer;
  }

  .label:hover,
  .value:hover {
    white-space: normal !important;
  }
}

#modal #tableData {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

// .theme-light {
//   #item-overview {
//     tr:nth-child(even) {
//       background-color: rgba($color: $gray-6, $alpha: 0.06);
//     }
//   }
// }

// .theme-dark {
//   #item-overview {
//     tr:nth-child(even) {
//       background-color: rgba($color: $gray-5, $alpha: 0.06);
//     }
//   }
// }
</style>
