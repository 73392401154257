<template>
  <div id="tabs">
    <!-- ... -->

    <Tab
      v-for="_tab in tabs"
      :key="_tab.id"
      ref="tab"
      :tab-id="_tab.id"
      :label="_tab.label"
      :is-active="isActive(_tab.route)"
      @click="handleClick(_tab)"
    />

    <!-- ... -->

    <!-- indicator -->

    <div ref="indicator" class="indicator"></div>

    <!-- ... -->
  </div>
</template>

<script>
import Tab from "./Tab.vue";

export default {
  name: "RouteTabs",

  components: { Tab },

  props: {
    tab: {
      type: String,
      default: "",
    },

    tabs: {
      type: Array,
      required: true,
    },
  },

  watch: {
    $route() {
      this.setTab();
    },

    tabs() {
      setTimeout(() => this.setTab(), 300);
    },
  },

  mounted() {
    if (this.tab && !this.isActive(this.tab)) {
      this.$router.push({ name: this.tab });
      return;
    }

    setTimeout(() => this.setTab(), 300);
  },

  methods: {
    setIndicatorPosition(tabId) {
      //const tabIdx = this.tabs.findIndex((tab) => tab.id === tabId);
      //const target = this.$refs.tab[0];
      let target;
      this.$refs.tab.forEach((tabControl) => {
        if (tabControl.tabId === tabId) {
          target = tabControl;
        }
      });
      const left = target.$el.offsetLeft;
      this.$refs.indicator.style.left = `${left}px`;

      const width = target.$el.clientWidth;
      this.$refs.indicator.style.width = `${width}px`;
    },

    isActive(route) {
      return this.$route.name === route;
    },

    setTab() {
      const activeRoute = this.$route.name;
      const _tab = this.tabs.find((__tab) => __tab.route === activeRoute);

      if (_tab) {
        this.setIndicatorPosition(_tab.id);
        return;
      }
    },

    handleClick(tab) {
      if (this.isActive(tab.route)) {
        return;
      }

      this.$router.push({ name: tab.route });
    },
  },
};
</script>

<style lang="scss" scoped>
#tabs {
  display: flex;
  align-items: center;
  position: relative;

  .indicator {
    position: absolute;
    bottom: -0.5px;
    height: 3px;
    background-color: var(--primary);
    border-radius: 4px 4px 0px 0px;
    transition: all 0.25s ease;
  }
}
</style>
