<template>
  <div>
    <Sheet :value="value" @input="$emit('input', false)">
      <template #title> Quick Help </template>

      <template #default>
        <div id="quick-help">
          <div class="section-title">Overview</div>

          <VideoCard
            caption="Learn all about the module by watching the 5 minute video"
            thumbnail="workspace"
            :page-help="pageHelp"
            @videoclick="$emit('videoclick')"
          />

          <div class="section-title">Related videos</div>
          <template v-if="pageHelp === 'WORKFLOW'">
            <ListItem
              v-for="item in workFlow"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
              @click="workFlowClick(item)"
            />
          </template>
          <template v-if="pageHelp === 'FORM'">
            <ListItem
              v-for="item in form"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
              @click="workFlowClick(item)"
            />
          </template>
          <template v-if="pageHelp === 'REPOSITORY'">
            <ListItem
              v-for="item in repository"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
              @click="workFlowClick(item)"
            />
          </template>
          <template v-else>
            <ListItem
              icon="eva-film-outline"
              label="How to add workspace?"
              class="list-item"
            />
            <ListItem
              icon="eva-film-outline"
              label="How to create a Form?"
              class="list-item"
            />
            <ListItem
              icon="eva-film-outline"
              label="How to download the report pdf?"
              class="list-item"
            />
          </template>

          <div class="section-title">Related links</div>

          <template v-if="pageHelp === 'WORKFLOW'">
            <ListItem
              v-for="item in relatedLinks"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
            />
          </template>
          <template v-else-if="pageHelp === 'FORM'">
            <ListItem
              v-for="item in relatedLinkForms"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
            />
          </template>
          <template v-else-if="pageHelp === 'REPOSITORY'">
            <ListItem
              v-for="item in relatedLinkRepository"
              :key="item.id"
              icon="eva-film-outline"
              :label="item.label"
              class="list-item"
            />
          </template>
          <template v-else>
            <ListItem
              icon="eva-external-link"
              label="Add a dashboard chart"
              class="list-item"
            />
            <ListItem
              icon="eva-external-link"
              label="Create a Form"
              class="list-item"
            />
            <ListItem
              icon="eva-external-link"
              label="Delete a workspace"
              class="list-item"
            />
          </template>
        </div>
      </template>
    </Sheet>

    <QuickHelpModal
      v-model="workflowModal"
      :is-header="false"
      width="70vw"
      height="96vh"
      has-footer
    >
      <!-- title -->

      <!-- ... -->
      <template #default>
        <div
          style="
            position: relative;
            padding-bottom: calc(50.70717423133236% + 42px);
            height: 0;
          "
          class="expand"
        >
          <iframe
            :src="selectedItem.link"
            title="Workflow - Process Design"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          icon="mdi-close"
          color="primary"
          @click="workflowModal = false"
        />
      </template>
    </QuickHelpModal>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import VideoCard from "@/components/common/card/VideoCard.vue";
import ListItem from "@/components/common/ListItem.vue";
import QuickHelpModal from "@/components/common/popup/QuickHelpModal.vue";

export default {
  name: "QuickHelp",

  components: { Sheet, VideoCard, ListItem, QuickHelpModal },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    pageHelp: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      workFlow: [
        {
          id: this.$nano.id(),
          label: "How to create new Workflow? ",
          link: "https://app.supademo.com/embed/clxd8hz5u1bqat2oeke7kkoew",
        },
        {
          id: this.$nano.id(),
          label: "How to connect Folders in Workflow?",
          link: "https://app.supademo.com/embed/clxd7tkoo1b80t2oewkmcvht1",
        },
        {
          id: this.$nano.id(),
          label: "How to Enable Notification on the node level?",
          link: "https://app.supademo.com/embed/clxbirpmz0rgat2oea5devc2m",
        },
        {
          id: this.$nano.id(),
          label: "How to add Document checklist on each of the nodes?",
          link: "https://app.supademo.com/embed/clxesrdp81s6kt2oesojunb10",
        },
        {
          id: this.$nano.id(),
          label:
            "How to add secure controls to visible / Editable on each nodes?",
          link: "https://app.supademo.com/embed/clxeo8mmp1q8ot2oef1qnxh2n",
        },
      ],
      relatedLinks: [
        {
          id: this.$nano.id(),
          label: "Create a Form ",
        },
        {
          id: this.$nano.id(),
          label: "Create a Folders",
        },
        {
          id: this.$nano.id(),
          label: "Configure Weekly Schedular",
        },
        {
          id: this.$nano.id(),
          label: "Delete Workflow",
        },
        {
          id: this.$nano.id(),
          label: "Configre Delegates",
        },
      ],
      form: [
        {
          id: this.$nano.id(),
          label: "How to create new Form? ",
          link: "https://app.supademo.com/embed/clxd8vlxd1by1t2oe6icxf5nr",
        },
        {
          id: this.$nano.id(),
          label: "How to Configure Master Form?",
          link: "https://app.supademo.com/embed/clxeypgjq1taat2oeste1kqly",
        },
        {
          id: this.$nano.id(),
          label: "How to add Panels and Controls on the Forms?",
          link: "https://app.supademo.com/embed/clxe5sifa1jubt2oeeqe6g5yx",
        },
        {
          id: this.$nano.id(),
          label: "How to configure the Controls Properties?",
          link: "https://app.supademo.com/embed/clxeb6bpn1lo3t2oescrrnarp",
        },
        {
          id: this.$nano.id(),
          label:
            "How to assign Default values? (login name / login email / Date Fields)",
          link: "https://app.supademo.com/embed/clxf1en5q1tnst2oe2lw8lull",
        },
        {
          id: this.$nano.id(),
          label: "How to apply settings on Calendar Picker?",
          link: "https://app.supademo.com/embed/clxenredh1pvyt2oensroin4m",
        },
        {
          id: this.$nano.id(),
          label: "How to connect with Master Inputs?",
          link: "https://app.supademo.com/embed/clxg1psol1xwjt2oeo843dd2f",
        },
        {
          id: this.$nano.id(),
          label: "How to enable controls based on the selected value?",
          link: "https://app.supademo.com/embed/clxfr9qln1wy8t2oeki9cigqx",
        },
        {
          id: this.$nano.id(),
          label: "How to apply readonly controls based on the selected Value?",
          link: "https://app.supademo.com/embed/clxfwe5z41xb9t2oez0l7ufiz",
        },
      ],
      relatedLinkForms: [
        {
          id: this.$nano.id(),
          label: "Create a Workflow ",
        },
        {
          id: this.$nano.id(),
          label: "Create a Folders",
        },
        {
          id: this.$nano.id(),
          label: "Configure QR Code Generator",
        },
        {
          id: this.$nano.id(),
          label: "Delete Forms",
        },
      ],
      repository: [
        {
          id: this.$nano.id(),
          label: "How to upload files on Static Folder?",
          link: "https://app.supademo.com/embed/clxqan0n800m3tj3fz0zayzcf",
        },
        {
          id: this.$nano.id(),
          label: "How to do manual indexing (with freehand)?",
          link: "https://app.supademo.com/embed/clxrbyddv4bs7t2oewhreca4a",
        },
        {
          id: this.$nano.id(),
          label: "How to do Content Search?",
          link: "https://app.supademo.com/embed/clxrdmmu74cbpt2oecuqf41am",
        },
        {
          id: this.$nano.id(),
          label: "How to do Global Search?",
          link: "https://app.supademo.com/edit/clxrdeqvl4c7st2oetzsbpz3p",
        },
        {
          id: this.$nano.id(),
          label: "How to upload files on dynamic Folder?",
          link: "https://app.supademo.com/embed/clxkjn41t2y6jt2oeea75ejui",
        },
        {
          id: this.$nano.id(),
          label: "How to upload the files into folders?",
          link: "https://app.supademo.com/embed/clxqa1xmy4888t2oev1skml3x",
        },
        {
          id: this.$nano.id(),
          label: "How to send the files to auto process?",
          link: "https://app.supademo.com/embed/clxrjr2wk4d0pt2oe26rima2o",
        },
        {
          id: this.$nano.id(),
          label: "How to Assign Document Notifications?",
          link: "https://app.supademo.com/embed/clxrcnvuo4byot2oec34ew455",
        },
        {
          id: this.$nano.id(),
          label: "How to download / print?",
          link: "https://app.supademo.com/embed/clxrd48pq4c5dt2oeb1dv0bjt",
        },
      ],
      relatedLinkRepository: [
        {
          id: this.$nano.id(),
          label: "Create a Workflow ",
        },
        {
          id: this.$nano.id(),
          label: "Create a Folders",
        },
        {
          id: this.$nano.id(),
          label: "Configure QR Code Generator",
        },
        {
          id: this.$nano.id(),
          label: "Delete Forms",
        },
      ],
      workflowModal: false,
      selectedItem: {},
    };
  },

  methods: {
    workFlowClick(item) {
      this.workflowModal = true;
      this.selectedItem = item;
    },
  },
};
</script>

<style lang="scss" scoped>
#quick-help {
  padding: 16px;

  .section-title {
    @extend .title-3;
    margin-top: 24px;
    margin-bottom: 8px;

    &:first-child {
      margin-top: 0px;
    }
  }

  .list-item {
    margin: 0px -16px;
  }
}
</style>
