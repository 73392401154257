import { excelParser } from "@/helpers/export-data.js";

export function exportData(data, fileName) {
  if (!data) {
    return this.$alert.info("Data not found");
  }

  let dt = new Date();
  if (!fileName) {
    fileName = `${dt.getFullYear()}${
      dt.getMonth() + 1
    }${dt.getDate()}${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
  }
  return excelParser().exportDataFromJSON(data, fileName, null);
}
