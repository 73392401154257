<template>
  <Sheet
    :value="value"
    width="30vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title> Add Contract</template>

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="First Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="firstName"
                label="First Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Last Name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="lastName"
                label="Last Name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="DOB"
              :rules="{ required: true }"
            >
              <DateField
                v-model="dob"
                label="DOB"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <NumberField
              v-model="currentAge"
              label="Current Age"
              is-mandatory
              is-readonly
              class="q-mb-sm"
            />

            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              :rules="{ required: true, email: true }"
            >
              <TextField
                v-model="email"
                label="Email"
                is-mandatory
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <TextField
              v-model="loanNumber"
              label="Loan Number"
              class="q-mb-sm"
            />

            <ValidationProvider
              v-slot="{ errors }"
              name="Product"
              :rules="{ required: true }"
            >
              <MultiSelectField
                v-model="products"
                label="Product"
                is-mandatory
                :options="productList"
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Lender"
              :rules="{ required: true }"
            >
              <SelectField
                v-model="lender"
                label="Lender"
                is-mandatory
                :options="lenderList"
                :error="errors[0]"
                class="q-mb-sm"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        label="Save and Send Package"
        color="primary"
        @click="createContract"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { custom } from "@/api/factory.js";
export default {
  name: "AddContract",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    DateField,
    NumberField,
    MultiSelectField,
    SelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    lenderList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      dob: "",
      currentAge: 0,
      email: "",
      loanNumber: "",
      products: ["Involuntary Unemployment"],
      productList: [
        {
          id: this.$nano.id(),
          label: "Critical Illness",
          value: "Critical Illness",
        },
        {
          id: this.$nano.id(),
          label: "Disability",
          value: "Disability",
        },
        {
          id: this.$nano.id(),
          label: "Involuntary Unemployment",
          value: "Involuntary Unemployment",
        },
        {
          id: this.$nano.id(),
          label: "Life",
          value: "Life",
        },
        {
          id: this.$nano.id(),
          label: "Lifetime Milestone",
          value: "Lifetime Milestone",
        },
        {
          id: this.$nano.id(),
          label: "Sickness or Injury",
          value: "Sickness or Injury",
        },
        {
          id: this.$nano.id(),
          label: "Unpaid Family Leave",
          value: "Unpaid Family Leave",
        },
      ],
      lender: "",
    };
  },

  watch: {
    dob: {
      deep: true,
      immediate: true,
      handler() {
        if (this.dob) {
          this.currentAge = this.calculateAge(this.dob);
        } else {
          this.currentAge = 0;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.firstName = "";
      this.lastName = "";
      this.dob = "";
      this.currentAge = 0;
      this.email = "";
      this.loanNumber = "";
      this.products = ["Involuntary Unemployment"];
      this.lender = "";
    },

    calculateAge(birthDate) {
      if (!birthDate) return;

      const currentDate = new Date();
      if (new Date(birthDate) > currentDate) {
        return "";
      }

      const diffTime = currentDate - new Date(birthDate);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return Math.floor(totalDays / 365.25);
    },

    async createContract() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.$store.commit("showLoadingBar");
      let input = {
        firstName: this.firstName,
        lastName: this.lastName,
        dob: this.dob,
        loanNumber: this.loanNumber,
        product: this.products,
      };
      const { error } = await custom.createContract(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      input.lender = this.lender;
      input.currentAge = this.currentAge;
      input.email = this.email;
      this.$emit("initiate", input);
      this.reset();
      this.closeSheet();
    },
  },
};
</script>
<style lang="scss"></style>
